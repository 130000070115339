import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
	company_id: Yup.string().required('Company is Required').nullable(),
	si_begin: Yup.string()
		.matches(/^\d{1,7}$/, 'Begin should not be more than 7-digit number')
		.nullable(),
	si_end: Yup.string()
		.matches(/^\d{1,7}$/, 'End should not be more than 7-digit number')
		.nullable(),
	sid_begin: Yup.string()
		.matches(/^\d{1,7}$/, 'Begin should not be more than 7-digit number')
		.nullable(),
	sid_end: Yup.string()
		.matches(/^\d{1,7}$/, 'End should not be more than 7-digit number')
		.nullable(),
	or_begin: Yup.string()
		.matches(/^\d{1,7}$/, 'Begin should not be more than 7-digit number')
		.nullable(),
	or_end: Yup.string()
		.matches(/^\d{1,7}$/, 'End should not be more than 7-digit number')
		.nullable(),
	cr_begin: Yup.string()
		.matches(/^\d{1,7}$/, 'Begin should not be more than 7-digit number')
		.nullable(),
	cr_end: Yup.string()
		.matches(/^\d{1,7}$/, 'End should not be more than 7-digit number')
		.nullable()
});
