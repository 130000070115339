import RequireAuth from 'components/RequireAuth';
import SerialNumberRoute from './SerialNumbers';
import Layout from 'components/Layout';
import Inventory from './Inventory';
import Login from './Login/router';

import { InvoicesRoute } from './Invoices';
import { ReportsRoute } from './Reports';
import { UsersRoute } from './Users';

const Main = [
	{
		element: <RequireAuth />, // this identify if the member is authenticated or not
		children: [
			{
				element: <Layout />, // when no active params in the URI
				children: [
					{
						path: '/',
						// when the membership or active or not expired
						// element: <RouteGuard />,
						children: [
							{
								path: '/',
								element: <></>
							},
							UsersRoute,
							InvoicesRoute,
							ReportsRoute,
							{
								path: '/inventory',
								element: <Inventory />
							},
							{
								path: '/serial-numbers',
								element: <SerialNumberRoute />
							}
						]
					}
				]
			}
		]
	}
];

export const routes = [...Login, ...Main];
