import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const CustomMenu = ({ text, items, handleAction }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (option) => {
		setAnchorEl(null);

		if (handleAction instanceof Function) {
			handleAction(option);
		}
	};

	return (
		<div>
			<IconButton
				aria-label="more"
				aria-haspopup="true"
				aria-controls="long-menu"
				onClick={handleClick}
			>
				{text}
			</IconButton>
			<Menu
				keepMounted
				open={open}
				id="long-menu"
				anchorEl={anchorEl}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: 48 * 4.5,
						width: '20ch'
					}
				}}
			>
				{items.map((option, key) => (
					<MenuItem
						key={key}
						value={option.value}
						onClick={(e) => handleSelect(option.type)}
					>
						{option.text}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

CustomMenu.defaultProps = {
	items: []
};

CustomMenu.propTypes = {
	items: PropTypes.array
};

export default CustomMenu;
