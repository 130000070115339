import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '100vh',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		flexWrap: 'wrap',
		paddingTop: 40,
		paddingBottom: 40,
		[theme.breakpoints.down('md')]: {
			padding: 0
		}
	},
	cardContainer: {
		padding: '10px 50px 10px 50px',
		[theme.breakpoints.down('sm')]: {
			// to small screens ex. cellphone, tablet
			padding: '10px 20px 10px 20px'
		},
		[theme.breakpoints.down('sm')]: {
			// to small screens ex. cellphone, tablet
			padding: '10px 20px 10px 20px'
		}
	},
	card: {
		width: '30%',
		height: '100%',
		flexBasis: 500,
		marginRight: 60,
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%',
			marginRight: 0
		}
	},
	background: {
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		width: '100%',
		height: '100vh'
	},
	loginBackground: {
		width: 500,
		height: 600
	},
	loginContainer: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'right',
		top: 80,
		right: 80
	},
	textLink: {
		color: theme.palette.primary.main,
		borderBottom: '1px solid',
		cursor: 'pointer',
		fontWeight: 700
	}
}));
