import { Box, CardContent, Card, Paper } from '@mui/material';

const TableCardLayout = ({ children }) => {
	return (
		<Paper elevation={10}>
			<Box mb={3}>
				<Card
					style={{
						width: '100%'
					}}
				>
					<CardContent>{children}</CardContent>
				</Card>
			</Box>
		</Paper>
	);
};

export default TableCardLayout;
