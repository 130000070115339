import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
	name: Yup.string().required('Name is Required').nullable(),
	tin: Yup.string()
		.matches(
			/^\d{3}-\d{3}-\d{3}-\d{3,5}$/,
			'TIN must be valid and formatted as ###-###-###-### or ###-###-###-#####'
		)
		.required('TIN is Required')
		.nullable(),
	address: Yup.string().required('Address is Required').nullable(),
	agent_id: Yup.string().required('Agent is Required').nullable()
});
