import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { useLoginMutation } from './api/login';
import { useCallback, useEffect } from 'react';
import { useStyles } from './utils/style';

import PasswordField from 'components/Form/Fields/PasswordField';
import VectorBackground from 'assets/svg/layered-waves-bg.svg';
import logo from '../../assets/images/inventory-logo.png';
import Textfield from 'components/Form/Fields/TextField';
import CardContent from '@mui/material/CardContent';
import ValidationSchema from './utils/validation';
import EmailIcon from '@mui/icons-material/Email';
import FormikForm from 'components/Form/Formik';
import Button from 'components/Form/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function LoginPage() {
	const classes = useStyles();
	const { setSnackBarMessage } = useSnackBarMessage();
	const [login, { status, data, error }] = useLoginMutation();

	useEffect(() => {
		document.body.style.backgroundImage = `url('${VectorBackground}')`;
		document.body.style.backgroundRepeat = 'no-repeat';
		document.body.style.backgroundSize = 'cover';
		document.body.style.minHeight = '100vh';
	}, []);

	const memoizedSetUAT = useCallback(() => {
		if (status === 'fulfilled') {
			const { _uat } = data;
			localStorage.setItem('uat', _uat);
			window.location.reload();
		}
	}, [status, data]);

	useEffect(() => {
		memoizedSetUAT();
	}, [memoizedSetUAT]);

	const memoizedSetSnackBarMessage = useCallback(() => {
		if (error && error.data) {
			const { message } = error.data;
			setSnackBarMessage(message, STATUS.ERROR);
		}
	}, [error, setSnackBarMessage]);

	useEffect(() => {
		memoizedSetSnackBarMessage();
	}, [memoizedSetSnackBarMessage]);

	return (
		<Grid container className={classes.container}>
			<Card className={classes.card}>
				<CardContent>
					<FormikForm
						initialValues={{
							email: '',
							password: ''
						}}
						validationSchema={ValidationSchema}
						enableReinitialize={true}
						onSubmit={(value) => login(value)}
					>
						<Grid container className={classes.cardContainer}>
							<Grid item xs={12} align="center">
								<img
									height="60vw"
									alt="company-logo"
									src={logo}
									style={{
										paddingTop: 50,
										paddingBottom: 40
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<Box
									component="h2"
									fontSize={35}
									fontWeight={700}
									textAlign="center"
									sx={{ color: 'text.secondary' }}
								>
									Inventory System
									<Box
										component="p"
										fontSize={18}
										sx={{ color: '#7E7E7E', fontWeight: 700 }}
									>
										Login to continue
									</Box>
								</Box>
							</Grid>
							<Grid item xs={12} sx={{ py: 1 }}>
								<Textfield
									label="Email Address"
									name="email"
									placeholder="Ex:  juandelacruz@email.com"
									icon={
										<EmailIcon sx={{ color: 'primary.main', fontSize: 15 }} />
									}
								/>
							</Grid>
							<Grid item xs={12} sx={{ py: 1 }}>
								<PasswordField
									label="Password"
									name="password"
									placeholder="Ex: Juan2345"
								/>
							</Grid>
							<Grid item container xs={12} sx={{ py: 1 }} align="center">
								<Grid item xs={12}>
									<Box my={1}>
										<Button fullWidth variant="contained">
											Login
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Grid>
					</FormikForm>
				</CardContent>
			</Card>
		</Grid>
	);
}
