import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const clientApi = createApi({
	reducerPath: 'clientApi',
	baseQuery,
	tagTypes: ['Client'],
	endpoints: (builder) => ({
		getClient: builder.query({
			query: (body) => ({
				url: '/client/list',
				method: 'GET',
				params: body
			}),
			transformResponse: (response, meta, arg) => {
				return response.items.map((item) => ({
					id: item.id,
					value: item.id,
					label: item.name,
					agent_code: item.agent_code,
					address: item.address,
					tin: item.tin
				}));
			}
		})
	})
});

// Export the hooks as named exports
export const { useGetClientQuery } = clientApi;
