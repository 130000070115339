import { useGetSerialNumberMutation } from './api/serialNumbersApi';
import { useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';

import Autocomplete from 'components/Fields/AutoComplete';
import _ from 'lodash';

export default function CustomDropdown({
	label,
	required,
	value,
	type,
	name,
	isNew = false,
	...otherProps
}) {
	const SERIAL_TYPE = {
		SI: 'SI',
		SID: 'SID',
		CR: 'CR',
		OR: 'OR'
	};

	const {
		values: { company_id, invoice_id },
		setFieldValue
	} = useFormikContext();

	const [getSerialNumber, { data: options, isLoading: loading }] =
		useGetSerialNumberMutation();

	const memoizedGetSerialNumber = useCallback(() => {
		if (company_id) {
			getSerialNumber({
				type,
				company_id,
				invoice_id: !isNew ? invoice_id : 0
			});
		}
	}, [isNew, type, invoice_id, getSerialNumber, company_id]);

	useEffect(() => {
		memoizedGetSerialNumber();
	}, [memoizedGetSerialNumber]);

	const memoizedFieldValue = useCallback(() => {
		const serial_type = value?.serial_type;
		const serial_id = value?.serial_id;

		if (!_.isNull(serial_type)) {
			if (SERIAL_TYPE.SI === serial_type) {
				setFieldValue('si_id', serial_id);
			}
			if (SERIAL_TYPE.SID === serial_type) {
				setFieldValue('sid_id', serial_id);
			}
			if (SERIAL_TYPE.CR === serial_type) {
				setFieldValue('cr_id', serial_id);
			}
			if (SERIAL_TYPE.OR === serial_type) {
				setFieldValue('or_id', serial_id);
			}
		}
	}, [
		setFieldValue,
		value,
		SERIAL_TYPE.SI,
		SERIAL_TYPE.SID,
		SERIAL_TYPE.CR,
		SERIAL_TYPE.OR
	]);

	useEffect(() => {
		memoizedFieldValue();
	}, [memoizedFieldValue]);

	const configDropdownSelect = {
		...otherProps,
		name,
		label,
		value,
		keyName: 'serial_number',
		options: options ?? [],
		loading,
		disabled: loading,
		isRequired: required
	};

	return <Autocomplete {...configDropdownSelect} />;
}
