export const FIELDS = [
	{
		header: 'Serial Invoice No.',
		type: 'SI',
		current: {
			name: 'si'
		},
		new: {
			name: 'si_new'
		}
	},
	{
		header: 'Collection Receipt No.',
		type: 'CR',
		current: {
			name: 'cr'
		},
		new: {
			name: 'cr_new'
		}
	},
	{
		header: 'Official Receipt No.',
		type: 'OR',
		current: {
			name: 'or'
		},
		new: {
			name: 'or_new'
		}
	},
	{
		header: 'Service ID No.',
		type: 'SID',
		current: {
			name: 'sid'
		},
		new: {
			name: 'sid_new'
		}
	}
];
