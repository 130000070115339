import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient.js';

export const usersApi = createApi({
	reducerPath: 'usersApi',
	baseQuery,
	tagTypes: ['Users'],
	endpoints: (builder) => ({
		getUsers: builder.mutation({
			query: (body) => ({
				url: '/users',
				method: 'GET',
				params: body
			})
		}),
		createUser: builder.mutation({
			query: (body) => ({
				url: '/users',
				method: 'POST',
				body
			})
		}),
		updateUser: builder.mutation({
			query: ({ id, body }) => ({
				url: `/users/${id}`,
				method: 'PATCH',
				body
			})
		}),
		deleteUser: builder.mutation({
			query: (id) => ({
				url: `/users/${id}`,
				method: 'DELETE'
			})
		})
	})
});

// Export the hooks as named exports
export const {
	useGetUsersMutation,
	useCreateUserMutation,
	useUpdateUserMutation,
	useDeleteUserMutation
} = usersApi;
