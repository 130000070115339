import { createSlice } from '@reduxjs/toolkit';

const modalState = {
	ADD: {
		type: 'ADD',
		title: 'Add User',
		disableFields: false
	},
	EDIT: {
		type: 'EDIT',
		title: 'Edit User',
		disableFields: false
	},
	VIEW: {
		type: 'VIEW',
		title: 'View User',
		disableFields: false
	},
	DELETE: {
		type: 'DELETE',
		title: 'Delete User',
		disableFields: false
	}
};

const initialState = {
	form: {
		role: '',
		fname: null,
		mname: null,
		lname: null,
		suffix: null,
		email: null,
		password: null
	},
	modal: {
		title: 'Add User',
		disableFields: false
	}
};

const users = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setForm: (state, { payload }) => {
			const { id } = payload.row;
			const { option } = payload;
			const isEdit = option === 'EDIT' ?? false;

			state.form = { id, isEdit, ...payload.row };
			state.modal = modalState[payload.option];
		}
	}
});

export const defaultState = {
	row: {
		role: '',
		fname: null,
		mname: null,
		lname: null,
		suffix: null,
		email: null,
		password: null
	},
	option: 'ADD'
};
export const { setForm } = users.actions;
export default users.reducer;
