import { useStyles } from './styles/AppBar';

import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';

export default function CustomAppBar() {
	const classes = useStyles();

	return (
		<AppBar
			position="fixed"
			className={classes.appBar}
			sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
		>
			<Toolbar className={classes.toolBar}>
				<Box>
					<Typography
						variant="body2"
						component="p"
						color="#7E7E7E"
					></Typography>
					<Typography
						variant="body2"
						component="p"
						color="primary"
					></Typography>
				</Box>
			</Toolbar>
		</AppBar>
	);
}
