import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const TableSearchField = ({ keywords, isSearching }) => {
	const handleSetKeyword = (keyword) => {
		if (keywords instanceof Function) {
			keywords(keyword);
		}
	};

	return (
		<FormControl
			fullWidth
			size="small"
			variant="outlined"
			style={{ backgroundColor: '#EDEEEE' }}
		>
			<OutlinedInput
				id="search"
				name="search"
				autoComplete="off"
				placeholder="Search..."
				type={'text'}
				disabled={isSearching}
				onChange={(e) => handleSetKeyword(e.target.value)}
				endAdornment={
					<InputAdornment position="end">
						<SearchIcon />
					</InputAdornment>
				}
			/>
		</FormControl>
	);
};

export default TableSearchField;
