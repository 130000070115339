import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { Routes } from 'router';

import SnackbarCloseButton from 'components/SnackBar/SnackbarCloseButton';
import store from 'app/store';

export default function App() {
	return (
		<Provider store={store}>
			<SnackbarProvider
				maxSnack={3}
				action={(key) => <SnackbarCloseButton key={key} />}
			>
				<Routes />
			</SnackbarProvider>
		</Provider>
	);
}
