import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const companyApi = createApi({
	reducerPath: 'companyApi',
	baseQuery,
	tagTypes: ['Company'],
	endpoints: (builder) => ({
		getCompany: builder.mutation({
			query: (body) => ({
				url: '/company/list',
				method: 'GET',
				params: body
			})
			// transformResponse: (response, meta, arg) => {
			// 	return response.items.map((item) => ({
			// 		label: item.name,
			// 		value: item.id
			// 	}));
			// }
		})
	})
});

// Export the hooks as named exports
export const { useGetCompanyMutation } = companyApi;
