import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const agentsApi = createApi({
	reducerPath: 'agentsApi',
	baseQuery,
	tagTypes: ['Agents'],
	endpoints: (builder) => ({
		getAgents: builder.query({
			query: (body) => ({
				url: '/agents/list',
				method: 'GET',
				params: body
			}),
			transformResponse: (response, meta, arg) => {
				return response.items.map((item) => ({
					label: item.name,
					value: item.id
				}));
			}
		})
	})
});

// Export the hooks as named exports
export const { useGetAgentsQuery } = agentsApi;
