import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient.js';

export const reportsApi = createApi({
	reducerPath: 'reportsApi',
	baseQuery,
	tagTypes: ['Reports'],
	endpoints: (builder) => ({
		getReports: builder.mutation({
			query: (body) => ({
				url: '/reports',
				method: 'GET',
				params: body
			})
		})
	})
});

// Export the hooks as named exports
export const { useGetReportsMutation } = reportsApi;
