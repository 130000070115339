import { TABLE_ACTION_MENU } from 'components/Table/constants/table.constants';
import { TABLE_HEADERS } from './constants/table';
import { Table, Modal, Form } from './components';

import Box from '@mui/material/Box';

export default function Main() {
	const EVENT_NAME = 'submit/serial-number-form';
	return (
		<Box padding={2}>
			<Modal eventName={EVENT_NAME}>
				<Form eventName={EVENT_NAME} />
			</Modal>
			<Table headers={TABLE_HEADERS} actions={TABLE_ACTION_MENU} />
		</Box>
	);
}

export const InvoicesRoute = {
	path: '/serial-numbers',
	element: <Main />
};
