import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
	name: Yup.string()
		.matches(
			/^(?=.{1,50}$)[a-zÀ-ž.]+(?:['-_\.\s][a-zÀ-ž]+)*$/i,
			'Name must be minimum of 2 letters and contain only letters'
		)
		.nullable(),
	code: Yup.string().required('Code is Required').nullable()
});
