export const getTypeAction = (type) => {
	let action = '';
	switch (type) {
		case 'ADD':
			action = 'Created';
			break;
		case 'EDIT':
			action = 'Updated';
			break;
		case 'DELETE':
			action = 'Removed';
			break;
		default:
			action = 'Created';
	}

	return action;
};
