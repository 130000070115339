import LoginForm from './index';
import OnlyUnauth from 'components/OnlyUnauth';

const LoginFormRoute = [
	{
		element: <OnlyUnauth />,
		children: [
			{
				path: '/login',
				element: <LoginForm />
			}
		]
	}
];

export default LoginFormRoute;
