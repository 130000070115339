import { createSlice } from '@reduxjs/toolkit';
import currency from 'currency.js';

const modalState = {
	ADD: {
		type: 'ADD',
		title: 'Add Serial Number',
		disableFields: false
	},
	EDIT: {
		type: 'EDIT',
		title: 'Edit Serial Number',
		disableFields: false
	},
	VIEW: {
		type: 'VIEW',
		title: 'View Serial Number',
		disableFields: false
	}
};

const initialState = {
	form: {
		company_id: null,
		type: null,
		begin: null,
		end: null,
		date_issued: null
	},
	modal: {
		title: 'Add Serial Number',
		disableFields: false
	}
};

const peso = (value) =>
	currency(value, { symbol: '', separator: ',', decimal: '.' });

const reports = createSlice({
	name: 'reports',
	initialState,
	reducers: {
		setForm: (state, { payload }) => {
			const { id, amount, ...params } = payload.row;
			state.form = {
				id,
				...params,
				amount_non_format: amount,
				amount: peso(amount).format()
			};
			state.modal = modalState[payload.option];
		}
	}
});

export const defaultState = {
	row: {
		company_id: null,
		type: null,
		begin: null,
		end: null,
		date_issued: null
	},
	option: 'ADD'
};
export const { setForm } = reports.actions;
export default reports.reducer;
