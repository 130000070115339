import CustomTextField from 'components/Fields/TextField';
import { useFormikContext } from 'formik';
import _ from 'lodash';

export default function CustomDropdown({
	label,
	required,
	value,
	type,
	name,
	...otherProps
}) {
	const { values } = useFormikContext();
	const configDropdownSelect = {
		...otherProps,
		name,
		label,
		value,
		isRequired: required
	};

	return _.get(values, ['client_id']) ? (
		<CustomTextField {...configDropdownSelect} />
	) : (
		<></>
	);
}
