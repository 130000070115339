import { createSlice } from '@reduxjs/toolkit';

const modalState = {
	ADD: {
		type: 'ADD',
		title: 'Add Agent',
		disableFields: false
	},
	EDIT: {
		type: 'EDIT',
		title: 'Edit Agent',
		disableFields: false
	},
	VIEW: {
		type: 'VIEW',
		title: 'View Agent',
		disableFields: false
	},
	DELETE: {
		type: 'DELETE',
		title: 'Delete Agent',
		disableFields: false
	}
};

const initialState = {
	form: {
		name: null,
		code: null
	},
	modal: {
		title: 'Add Agent',
		disableFields: false
	}
};

const agents = createSlice({
	name: 'agents',
	initialState,
	reducers: {
		setForm: (state, { payload }) => {
			const { id, name, code } = payload.row;
			state.form = { id, name, code };
			state.modal = modalState[payload.option];
		}
	}
});

export const defaultState = {
	row: {
		name: null,
		code: null
	},
	option: 'ADD'
};
export const { setForm } = agents.actions;
export default agents.reducer;
