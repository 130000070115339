import { Outlet, useNavigate } from 'react-router-dom';
import { navLink } from './Drawer/utils/navigation';
import { useStyles } from './styles/drawer';
import CustomAppBar from './Header';
import React, { memo } from 'react';

import ListItemCollapse from './ListItemCollapse';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import CustomDrawer from './Drawer';
import NavLinks from './NavLinks';
import clsx from 'clsx';

export default memo(function PermanentDrawerLeft() {
	const [open, setOpen] = React.useState(true);
	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<Box id="container" className={classes.container}>
			<CssBaseline />
			<CustomAppBar />
			<CustomDrawer state={open}>
				<NavLinks
					onNavigate={(path) => navigate(path)}
					items={navLink}
					state={open}
				>
					<ListItemCollapse />
				</NavLinks>
			</CustomDrawer>
			<Box
				className={clsx(classes.drawerButton, {
					[classes.drawerButtonOpen]: open,
					[classes.drawerButtonClose]: !open
				})}
				onClick={(e) => setOpen((open) => (open = !open))}
				component="span"
				color="primary"
			>
				<PlayArrowIcon
					sx={{
						transform: open ? 'rotateY(180deg)' : 'rotateY(360deg)'
					}}
				/>
			</Box>
			<Box component="main" className={classes.main}>
				<Toolbar />
				<Outlet />
			</Box>
		</Box>
	);
});
