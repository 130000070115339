import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const loginApi = createApi({
	reducerPath: 'loginApi',
	baseQuery,
	tagTypes: ['Login'],
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (body) => ({
				url: '/auth/login',
				method: 'POST',
				body
			})
		})
	})
});

export const { useLoginMutation } = loginApi;
