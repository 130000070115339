import { useEffect, useCallback, Fragment } from 'react';
import { useFormikContext } from 'formik';
import { dispatch } from 'use-bus';
import { Formik } from 'formik';

const FormikWithSetErrors = ({ children, setErrors, getValues, getErrors }) => {
	const { setErrors: setBackendErrors, values, errors } = useFormikContext();

	const memoizedSetBackendErrors = useCallback(() => {
		setBackendErrors(setErrors);
	}, [setErrors, setBackendErrors]);

	useEffect(() => {
		if (setErrors) memoizedSetBackendErrors();
	}, [memoizedSetBackendErrors, setErrors]);

	// To get values from Formik Component
	const memoizedGetValue = useCallback(() => {
		if (getValues instanceof Function) {
			getValues(values);
		}
	}, [getValues, values]);

	useEffect(() => {
		memoizedGetValue();
	}, [values]);

	// To get errors from Formik Component
	const memoizedGetError = useCallback(() => {
		if (getErrors instanceof Function) {
			getErrors(errors);
		}
	}, [getErrors, errors]);

	useEffect(() => {
		memoizedGetError();
	}, [errors]);

	return <Fragment>{children}</Fragment>;
};

const FormikForm = ({ children, formName = '', ...otherProps }) => {
	const handleKeyDown = (event) => {
		// Check if the pressed key is "Enter"
		if (event?.keyCode === 13) {
			dispatch(formName);
		}
	};

	useEffect(() => {
		// Attach the event listener to the document
		document.addEventListener('keydown', handleKeyDown);

		// Clean up the event listener when the component is unmounted
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []); // Empty dependency array ensures the effect runs once when the component mounts

	return (
		<Formik {...otherProps}>
			<FormikWithSetErrors {...otherProps}>{children}</FormikWithSetErrors>
		</Formik>
	);
};

export default FormikForm;
