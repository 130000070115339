import { setForm, defaultState } from '../../reducers/serialNumberSlice.js';
import { useGetSerialNumberMutation } from '../../api/serial-number.js';
import useBus, { dispatch } from 'use-bus';
import { useDispatch } from 'react-redux';
import { useEffect, useCallback } from 'react';

import Table from 'components/Table';

const defaultPagination = {
	perPage: 5,
	page: 1,
	total_items: 10
};

export default function InstanceTable({ headers, actions }) {
	const [getSerialNumber, { data, isLoading }] = useGetSerialNumberMutation();
	const { items, ...pagination } = data ?? {};
	const stateDispatch = useDispatch();

	const memoizedGetGetSerialNumber = useCallback(() => {
		if (getSerialNumber instanceof Function) {
			getSerialNumber(defaultPagination);
		}
	}, [getSerialNumber]);

	useEffect(() => {
		memoizedGetGetSerialNumber(defaultPagination);
	}, [memoizedGetGetSerialNumber]);

	useBus(
		'refetch',
		() => {
			getSerialNumber(defaultPagination);
		},
		[]
	);

	return (
		<Table
			data={{
				loading: isLoading,
				headers,
				rows: {
					items
				},
				paginate: {
					...defaultPagination,
					...pagination,
					callback: (pagination) => {
						getSerialNumber(pagination);
					}
				},
				add_button: {
					show: true,
					text: '+ Add Serial Number',
					callback: () => {
						dispatch('modal/serial-numbers');
						stateDispatch(setForm(defaultState));
					}
				},
				menu: {
					show: true,
					items: actions,
					callback: (menu) => {
						dispatch('modal/serial-numbers');
						stateDispatch(setForm(menu));
					}
				},
				search: {
					show: true,
					callback: (keyword) => {
						getSerialNumber({
							...defaultPagination,
							search: keyword
						});
					}
				}
			}}
		/>
	);
}
