import { fetchBaseQuery } from '@reduxjs/toolkit/query';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const baseQuery = fetchBaseQuery({
	baseUrl: BASE_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = localStorage.getItem('uat');
		// If we have a token set in state, let's assume that we should be passing it.
		if (token) {
			headers.set('Authorization', `Bearer ${token}`);
		}
		return headers;
	}
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);

	if (result.error && result.error.status === 401) {
		//try to get a new token
		const refreshResult = await baseQuery(
			{ method: 'POST', url: '/auth/refresh-token' },
			api,
			extraOptions
		);

		if (refreshResult.data) {
			if (refreshResult.data.refresh === false) {
				localStorage.setItem('ref', false);
				localStorage.removeItem('uat');
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			} else {
				localStorage.setItem('ref', true);
				localStorage.setItem('uat', refreshResult.data._uat);
			}
			// store the new token
			// retry the initial query
			result = await baseQuery(args, api, extraOptions);
		} else {
			await localStorage.removeItem('uat');
		}
	}

	return result;
};

export default baseQueryWithReauth;
