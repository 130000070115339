import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	outlinedButton: {
		textTransform: 'none',
		fontWeight: 700,
		fontSize: 16,
		height: 40,
		width: 128,
		color: 'black'
	}
}));

export default useStyles;
