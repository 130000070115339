import { useState } from 'react';
import { useTheme } from '@mui/styles';
import { useStyles } from './style/index.style';
import {
	Box,
	Dialog,
	Divider,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	useMediaQuery,
	Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from './component/Button';
import Stack from '@mui/material/Stack';
import useBus, { dispatch } from 'use-bus';

const CustomModal = ({
	name,
	title,
	content,
	disabled,
	actions,
	maxWidth,
	viewFullScreen
}) => {
	const theme = useTheme();
	const classes = useStyles();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
	let [isOpen, setOpen] = useState(false);

	useBus(`modal/${name}`, () => setOpen((isOpen = !isOpen)), [isOpen]);

	const handleClose = () => {
		dispatch(`modal/${name}`);
	};

	return (
		<Dialog
			fullWidth
			open={isOpen}
			fullScreen={fullScreen || viewFullScreen}
			onClose={handleClose}
			aria-labelledby="responsive-dialog-title"
			disableBackdropClick
			maxWidth={maxWidth || 'sm'}
		>
			<Stack
				useFlexGap
				direction="row"
				flexWrap="wrap"
				spacing={{ xs: 1, sm: 2 }}
				justifyContent="space-between"
			>
				<DialogTitle>{title}</DialogTitle>
				<div>
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={handleClose}
						disabled={disabled}
					>
						<CloseIcon color="primary" />
					</IconButton>
				</div>
			</Stack>
			<Divider />
			<DialogContent>{content}</DialogContent>
			<DialogActions>
				{actions?.show && (
					<Box
						width="100%"
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						flexDirection="row"
					>
						<Box mx={2} my={1}>
							<Grid item>
								{actions?.left && (
									<Button
										style={{ width: '200px' }}
										variant="outlined"
										color="primary"
										disabled={actions?.left.disabled || disabled}
										onClick={(e) => actions?.left.callback()}
									>
										<strong>{actions?.left.text}</strong>
									</Button>
								)}
							</Grid>
						</Box>
						<Box mx={2} my={1}>
							<Grid item>
								{actions?.right && (
									<Button
										type="submit"
										style={{ width: '200px' }}
										size="medium"
										fullWidth
										variant="contained"
										color="primary"
										disabled={actions?.right.disabled || disabled}
										onClick={(e) => actions?.right.callback()}
									>
										<strong>{actions?.right.text}</strong>
									</Button>
								)}
							</Grid>
						</Box>
					</Box>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default CustomModal;
