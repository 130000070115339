import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

// this is used in main page and membership page
export default function RequireAuth() {
	const { isAuth } = useAuth();
	const location = useLocation();

	if (!isAuth()) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <Outlet />;
}
