import { InputAdornment, Autocomplete, TextField } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useStyles } from './utils/style.js';

import FieldLabel from 'components/Form/Fields/FieldLabel';
import ErrorIcon from '@mui/icons-material/Error';
import ClearButton from '../FieldClearButton';
import Box from '@mui/material/Box';
import * as React from 'react';
import clsx from 'clsx';
import _ from 'lodash';

export default function AutoComplete(props) {
	const {
		name,
		label,
		options,
		readOnly,
		isRequired,
		handleChange,
		disabled,
		setFieldValue,
		errors,
		values,
		icon,
		disableClearable,
		keyName,
		value,
		...otherProps
	} = props;

	const [valueSelected] = useState(_.get(values, [name]));
	const [_value, setValue] = React.useState('');
	const classes = useStyles();

	let configFieldLabel = {
		text: label,
		required: isRequired
	};

	let configTextField = {
		...otherProps,
		name,
		options,
		readOnly,
		disableClearable,
		freeSolo: true,
		required: isRequired,
		size: 'small',
		variant: 'outlined',
		id: 'auto-complete-field',
		value: _.get(values, [name], '')
	};

	const handleChanges = (event, value) => {
		setValue(value);
		if (handleChange instanceof Function) {
			handleChange(value);
		} else if (setFieldValue instanceof Function) {
			setFieldValue(name, value.value);
		}
	};

	const memoizedOptions = useCallback(() => {
		if (options?.length > 0) {
			const filteredValue = options?.filter((d) => {
				if (_.isObject(_.get(values, name))) {
					return (
						_.get(d.value, keyName) === _.get(_.get(values, name), keyName)
					);
				}

				return d.value === _.get(values, name);
			});

			if (filteredValue.length > 0) {
				setValue(filteredValue[0] ?? '');
			}
		}
	}, [keyName, values, name, options, setValue]);

	useEffect(() => {
		memoizedOptions();
	}, [memoizedOptions]);

	// when there's an error on errors obj
	if (_.has(errors, name) && !disabled) {
		configFieldLabel.error = true;
		configTextField.error = true;
		configTextField.helperText = _.get(errors, name);
	}

	return (
		<Box display="flex" flexDirection="column" flex={1}>
			<FieldLabel {...configFieldLabel} />
			<Autocomplete
				{...configTextField}
				sx={{
					'& .MuiOutlinedInput-root': {
						paddingRight: '10px!important'
					}
				}}
				defaultValue={valueSelected}
				onChange={handleChanges}
				disableClearable={true}
				options={options}
				value={_value}
				freeSolo
				renderInput={(params) => {
					return (
						<TextField
							value={''}
							{...params}
							{...configTextField}
							fullWidth={true}
							hiddenLabel={true}
							InputProps={{
								...params.InputProps,
								readOnly,
								endAdornment:
									_.get(values, [name]) && !disabled && !readOnly ? (
										<InputAdornment position="end">
											<ClearButton
												handleClick={(e) => {
													setValue('');
													if (handleChange instanceof Function) {
														handleChange(e.target.value);
													} else if (setFieldValue instanceof Function) {
														setFieldValue(name, '');
													}
												}}
											/>
										</InputAdornment>
									) : (
										_.has(errors, name) &&
										!disabled && (
											<ErrorIcon style={{ fontSize: 16, color: '#d32f2f' }} />
										)
									),
								startAdornment: (
									<InputAdornment position="start">{icon}</InputAdornment>
								)
							}}
							className={clsx({
								[classes.root]: !_.has(errors, name) && !disabled,
								[classes.error]: _.has(errors, name) && !disabled,
								[classes.disabled]: disabled
							})}
						/>
					);
				}}
			/>
		</Box>
	);
}
