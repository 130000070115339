import React, { useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';

import CustomTextField from 'components/Fields/TextField';
import currency from 'currency.js';
import _ from 'lodash';

export default function DecimalConverter({
	label,
	required,
	value,
	name,
	...otherProps
}) {
	const {
		setFieldValue,
		values: { amount },
		setFieldError
	} = useFormikContext();

	const peso = (value) =>
		currency(value, {
			symbol: '',
			separator: ',',
			decimal: '.'
		});

	const setAmountDecimal = useCallback(() => {
		if (_.isNumber(value)) {
			setFieldValue(name, peso(value).format());

			const cleanString = amount.replace(/,/g, '');
			const integerValue = parseInt(cleanString);

			if (integerValue) {
				setFieldError('amount_decimal', integerValue);
			}
		}
	}, [value, name, amount, setFieldValue, setFieldError]);

	useEffect(() => {
		setAmountDecimal();
	}, [setAmountDecimal]);

	const configDropdownSelect = {
		...otherProps,
		name,
		label,
		value,
		isRequired: required,
		onBlur: (e) => {
			const cleanString = value.replace(/,/g, '');
			const integerValue = parseInt(cleanString);

			setFieldValue('amount_decimal', integerValue);
			setFieldValue(name, peso(value).format());
		}
	};

	return <CustomTextField {...configDropdownSelect} />;
}
