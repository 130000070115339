import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import React from 'react';

export default function NavLinks({ items, state, onNavigate, children }) {
	return (
		<List>
			{items?.map((item, index) => {
				if (item?.children) {
					return <Box key={`nav-btn-${index}`}>{children}</Box>;
				}

				return (
					<Box key={`nav-btn-${index}`}>
						<ListItemButton
							button
							sx={{ py: 1 }}
							key={item.text}
							onClick={(e) => onNavigate(item.path)}
						>
							<ListItemIcon style={{ justifyContent: 'center' }}>
								{item.icon}
							</ListItemIcon>
							<ListItemText
								primary={
									<div
										style={{ lineHeight: 2.5, position: 'relative', top: 3 }}
									>
										{item?.text}
									</div>
								}
								style={{ margin: 0, color: state ? '#FFF' : '#074195' }}
							/>
						</ListItemButton>
					</Box>
				);
			})}
		</List>
	);
}
