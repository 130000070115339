import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const companyApi = createApi({
	reducerPath: 'companyApi',
	baseQuery,
	tagTypes: ['Company'],
	endpoints: (builder) => ({
		getCompany: builder.mutation({
			query: (body) => ({
				url: '/company',
				method: 'GET',
				params: body
			})
		}),
		createCompany: builder.mutation({
			query: (body) => ({
				url: '/company',
				method: 'POST',
				body
			})
		}),
		updateCompany: builder.mutation({
			query: ({ id, body }) => ({
				url: `/company/${id}`,
				method: 'PATCH',
				body
			})
		}),
		deleteCompany: builder.mutation({
			query: (id) => ({
				url: `/company/${id}`,
				method: 'DELETE'
			})
		})
	})
});

// Export the hooks as named exports
export const {
	useGetCompanyMutation,
	useCreateCompanyMutation,
	useUpdateCompanyMutation,
	useDeleteCompanyMutation
} = companyApi;
