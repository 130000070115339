import { TableHead, TableRow, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
	sticky: {
		position: 'sticky',
		left: 0,
		background: 'white',
		boxShadow: '5px 2px 5px grey'
	},
	header: {
		fontWeight: '750',
		fontSize: '12px',
		color: '#656565'
	}
});

const TableHeader = ({ items, menu }) => {
	const classes = useStyles();

	return (
		<TableHead>
			<TableRow>
				{items?.map((row, index) => (
					<TableCell className={classes.header} key={`header-key-${index}`}>
						{row.header}
					</TableCell>
				))}
				{menu.hasOwnProperty('show') && (
					<TableCell className={classes.sticky} />
				)}
			</TableRow>
		</TableHead>
	);
};

export default TableHeader;
