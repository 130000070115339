import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

const useStyles = makeStyles({
	button: {
		fontSize: '0.8rem',
		height: '2.5rem!important',
		textTransform: 'none!important'
	}
});

const TableAddButton = ({ show, ButtonText, handleClick }) => {
	const classes = useStyles();
	const handleCallBack = () => {
		if (handleClick instanceof Function) {
			handleClick();
		}
	};

	return (
		show && (
			<Button
				fullWidth
				size="small"
				color="primary"
				variant="contained"
				onClick={handleCallBack}
				className={classes.button}
			>
				<strong>{ButtonText}</strong>
			</Button>
		)
	);
};

export default TableAddButton;
