import { TABLE_ACTION_MENU } from 'components/Table/constants/table.constants';
import { TABLE_HEADERS } from './constants/table';
import { Table } from './components';

import Box from '@mui/material/Box';

export default function Main() {
	return (
		<Box padding={2}>
			<Table headers={TABLE_HEADERS} actions={TABLE_ACTION_MENU} />
		</Box>
	);
}

export const ReportsRoute = {
	path: '/reports',
	element: <Main />
};
