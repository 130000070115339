import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useStyles } from '../styles/table';

const CustomMenu = ({ items, handleAction }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSelect = (option) => {
		setAnchorEl(null);
		if (handleAction instanceof Function) {
			handleAction(option);
		}
	};

	return (
		<div>
			<div
				className={classes.downloadBtn}
				style={{ cursor: 'pointer' }}
				onClick={handleClick}
			>
				Export as <ArrowDropDownIcon />
			</div>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: 48 * 4.5,
						width: '20ch'
					}
				}}
			>
				{items.map((option, key) => (
					<MenuItem key={key} onClick={(e) => handleSelect(option.type)}>
						{option.text}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

CustomMenu.defaultProps = {
	items: []
};

CustomMenu.propTypes = {
	items: PropTypes.array
};

export default CustomMenu;
