import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { useCallback, useEffect, useRef } from 'react';
import { ValidationSchema } from './utils/validation';
import { getTypeAction } from 'utils/actionTypes';
import useBus, { dispatch } from 'use-bus';
import { useSelector } from 'react-redux';

import {
	useCreateUserMutation,
	useUpdateUserMutation,
	useDeleteUserMutation
} from '../../api/users.js';

import PasswordField from 'components/Fields/PasswordField';
import CustomTextField from 'components/Fields/TextField';
import FormikWrapper from 'components/Form/FormikWrapper';
import FormikForm from 'components/Form/Formik';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const FormikPasswordField = FormikWrapper(PasswordField);
const FormikTextField = FormikWrapper(CustomTextField);
const DISPATCH_NAME = 'modal/users';
const FORM_TYPES = {
	ADD: 'ADD',
	EDIT: 'EDIT',
	VIEW: 'VIEW',
	DELETE: 'DELETE'
};

export default function Form({ eventName }) {
	const modal = useSelector((state) => state.users.modal);
	const form = useSelector((state) => state.users.form);
	const formRef = useRef();

	const { setSnackBarMessage } = useSnackBarMessage();
	const [createUser, { status: AddStatus, error: AddError }] =
		useCreateUserMutation();
	const [updateUser, { status: EditStatus, error: EditError }] =
		useUpdateUserMutation();
	const [deleteInvoices, { status: DeleteStatus }] = useDeleteUserMutation();
	const onViewModal = modal.type === FORM_TYPES.VIEW;

	const memoizedStatusDispatcher = useCallback(() => {
		if (
			AddStatus === 'fulfilled' ||
			EditStatus === 'fulfilled' ||
			DeleteStatus === 'fulfilled'
		) {
			setSnackBarMessage(
				`Succesfully ${getTypeAction(modal.type)}`,
				STATUS.SUCCESS
			);
			dispatch(DISPATCH_NAME);
			dispatch('refetch');
		}
	}, [AddStatus, EditStatus, DeleteStatus, setSnackBarMessage, modal.type]);

	useEffect(() => {
		memoizedStatusDispatcher();
	}, [memoizedStatusDispatcher]);

	useBus(
		eventName,
		() => {
			if (formRef.current) {
				formRef.current.handleSubmit();
			}
			if (modal.type === FORM_TYPES.DELETE) {
				const { id } = form;
				deleteInvoices(id);
			}
		},
		[formRef]
	);

	const handleFormSubmit = (values) => {
		if (modal.type === FORM_TYPES.ADD) {
			createUser(values);
		}
		if (modal.type === FORM_TYPES.EDIT) {
			const { id } = values;
			updateUser({
				id,
				body: values
			});
		}
	};

	return modal.type !== FORM_TYPES.DELETE ? (
		<FormikForm
			setErrors={{
				...AddError?.data?.error,
				...EditError?.data?.error
			}}
			formName={eventName}
			innerRef={formRef}
			initialValues={form}
			enableReinitialize={true}
			validationSchema={ValidationSchema}
			onSubmit={(values) => handleFormSubmit(values)}
		>
			<Stack spacing={1}>
				<FormikTextField
					label="First Name"
					name="fname"
					readOnly={onViewModal}
				/>
				<FormikTextField
					label="Middle Name"
					name="mname"
					readOnly={onViewModal}
				/>
				<Stack spacing={1} direction="row">
					<FormikTextField
						label="Last Name"
						name="lname"
						readOnly={onViewModal}
					/>
					<FormikTextField
						label="Suffix"
						name="suffix"
						readOnly={onViewModal}
					/>
				</Stack>
				<FormikTextField label="Email" name="email" readOnly={onViewModal} />
				{modal.type === FORM_TYPES.ADD && (
					<>
						<Box>
							<h2>Credential</h2>
							<Divider />
						</Box>
						<Stack spacing={1} direction="row">
							<FormikPasswordField label="Password" name="password" />
							<FormikPasswordField
								label="Confirm Password"
								name="confirmPassword"
							/>
						</Stack>
					</>
				)}
				{/* <FormikUserRolesDropdown /> */}
			</Stack>
		</FormikForm>
	) : (
		<>Do you want to delete this User ? </>
	);
}
