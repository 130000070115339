import Avatar from '@mui/material/Avatar';

export default function CustomAvatar({
	logo,
	width,
	height,
	border = '6px solid #E9E9E9',
	...otherProps
}) {
	const config = {
		...otherProps,
		src: logo,
		alt: 'User Avatar',
		sx: { width, height, border }
	};

	return <Avatar {...config} />;
}
