import { TABLE_HEADERS, TABLE_ACTION_MENU } from './constants/table';
import { Table, Modal, Form, VoidForm } from './components';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';

export default function Invoices() {
	const modal = useSelector((state) => state.invoices.modal);
	const onViodModal = modal.type === 'VOID';
	const EVENT_NAME = 'submit/invoices-form';

	return (
		<Box padding={2}>
			<Modal eventName={EVENT_NAME}>
				{onViodModal ? (
					<VoidForm eventName={EVENT_NAME} />
				) : (
					<Form eventName={EVENT_NAME} />
				)}
			</Modal>
			<Table headers={TABLE_HEADERS} actions={TABLE_ACTION_MENU} />
		</Box>
	);
}

export const InvoicesRoute = {
	path: '/invoices',
	element: <Invoices />
};
