import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const invoicesApi = createApi({
	reducerPath: 'invoicesApi',
	baseQuery,
	tagTypes: ['Invoices'],
	endpoints: (builder) => ({
		getInvoices: builder.mutation({
			query: (body) => ({
				url: '/invoices',
				method: 'GET',
				params: body
			})
		}),
		createInvoices: builder.mutation({
			query: (body) => ({
				url: '/invoices',
				method: 'POST',
				body
			})
		}),
		updateInvoices: builder.mutation({
			query: ({ id, body }) => ({
				url: `/invoices/${id}`,
				method: 'PATCH',
				body
			})
		}),
		deleteInvoices: builder.mutation({
			query: (id) => ({
				url: `/invoices/${id}`,
				method: 'DELETE'
			})
		}),
		voidInvoices: builder.mutation({
			query: ({ id, body }) => ({
				url: `/invoices/void/${id}`,
				method: 'PATCH',
				body
			})
		})
	})
});

// Export the hooks as named exports
export const {
	useGetInvoicesMutation,
	useCreateInvoicesMutation,
	useUpdateInvoicesMutation,
	useDeleteInvoicesMutation,
	useVoidInvoicesMutation
} = invoicesApi;
