export const TABLE_HEADERS = [
	{ header: 'ID', name: 'id', width: 20 },
	{ header: 'SALES INVOICE', name: 'si.serial_number', width: 15 },
	{ header: 'COLLECTION RECEIPT', name: 'cr.serial_number', width: 15 },
	{ header: 'OFFICIAL RECEIPT', name: 'or.serial_number', width: 15 },

	{ header: 'COMPANY NAME', name: 'company_name', width: 20 },
	{ header: 'CLIENT NAME', name: 'client_name', width: 15 },
	{ header: 'AMOUNT', name: 'amount', width: 15 },
	{ header: 'AGENT', name: 'agent_name', width: 15 },
	{ header: 'DATE ISSUED', name: 'date_issued', width: 15 },
	{ header: 'DATE CREATED', name: 'created_at', width: 30 },
	{ header: '', name: 'action', width: 15 }
];

export const TABLE_ACTION_MENU = [
	{ text: 'View', type: 'VIEW' },
	{ text: 'Edit', type: 'EDIT' },
	{ text: 'Void', type: 'VOID' },
	{ text: 'Delete', type: 'DELETE' }
];
