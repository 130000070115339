import BallotRoundedIcon from '@mui/icons-material/BallotRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import { ReactComponent as Profile } from 'assets/svg/Profile.svg';

export const navLink = [
	{
		text: 'Invoices',
		icon: <BallotRoundedIcon style={{ color: '#FFF' }} />,
		path: '/invoices',
		restricted: false,
		expired: false,
		declined: true
	},
	{
		text: 'Inventory',
		icon: <ListAltRoundedIcon style={{ color: '#FFF' }} />,
		path: '/inventory',
		restricted: false,
		expired: true,
		declined: true
	},
	{
		text: 'Serial Numbers',
		icon: <ListAltRoundedIcon style={{ color: '#FFF' }} />,
		path: '/serial-numbers',
		restricted: false,
		expired: true,
		declined: true
	},

	{
		text: 'Reports',
		icon: <AssessmentRoundedIcon style={{ color: '#FFF' }} />,
		path: '/reports',
		restricted: false,
		expired: false,
		declined: true
	},
	{
		text: 'Users',
		icon: <Profile />,
		path: '/users',
		restricted: false,
		expired: true,
		declined: true
	}
];
