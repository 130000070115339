import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const agentsApi = createApi({
	reducerPath: 'agentsApi',
	baseQuery,
	tagTypes: ['Agents'],
	endpoints: (builder) => ({
		getAgents: builder.mutation({
			query: (body) => ({
				url: '/agents',
				method: 'GET',
				params: body
			})
		}),
		createAgent: builder.mutation({
			query: (body) => ({
				url: '/agents',
				method: 'POST',
				body
			})
		}),
		updateAgent: builder.mutation({
			query: ({ id, body }) => ({
				url: `/agents/${id}`,
				method: 'PATCH',
				body
			})
		}),
		deleteAgent: builder.mutation({
			query: (id) => ({
				url: `/agents/${id}`,
				method: 'DELETE'
			})
		})
	})
});

// Export the hooks as named exports
export const {
	useGetAgentsMutation,
	useCreateAgentMutation,
	useUpdateAgentMutation,
	useDeleteAgentMutation
} = agentsApi;
