import { serialNumberApi } from 'features/SerialNumbers/api/serial-number';
import { clientListApi } from 'reusables/fields/ClientField/api/clients';
import { companyApi } from 'features/Inventory/Companies/api/companies';
import { clientApi } from 'features/Inventory/Clients/api/clients';
import { agentsApi } from 'features/Inventory/Agents/api/agents';
import { invoicesApi } from 'features/Invoices/api/invoices';
import { reportsApi } from 'features/Reports/api/reports';
import { usersApi } from 'features/Users/api/users';
import { loginApi } from 'features/Login/api/login';
import { configureStore } from '@reduxjs/toolkit';

import serialNumberReducer from 'features/SerialNumbers/reducers/serialNumberSlice';
import companyReducer from 'features/Inventory/Companies/reducers/companySlice';
import clientReducer from 'features/Inventory/Clients/reducers/clientSlice';
import agentsReducer from 'features/Inventory/Agents/reducers/agentSlice';
import invoicesReducer from 'features/Invoices/reducers/invoicesReducer';
import reportsReducer from 'features/Reports/reducers/reportsSlice';
import usersReducer from 'features/Users/reducers/usersSlice';

const store = configureStore({
	reducer: {
		clients: clientReducer,
		company: companyReducer,
		serialNumber: serialNumberReducer,
		invoices: invoicesReducer,
		users: usersReducer,
		agents: agentsReducer,
		reports: reportsReducer,

		reportsApi: reportsApi.reducer,
		clientApi: clientApi.reducer,
		clientListApi: clientListApi.reducer,
		companyApi: companyApi.reducer,
		serialNumberApi: serialNumberApi.reducer,
		invoicesApi: invoicesApi.reducer,
		usersApi: usersApi.reducer,
		agentsApi: agentsApi.reducer,
		loginApi: loginApi.reducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			companyApi.middleware,
			clientApi.middleware,
			clientListApi.middleware,
			agentsApi.middleware,
			serialNumberApi.middleware,
			invoicesApi.middleware,
			reportsApi.middleware,
			usersApi.middleware,
			loginApi.middleware
		)
});

export default store;
