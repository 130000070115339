import { setForm, defaultState } from '../../reducers/reportsSlice.js';
import { useGetReportsMutation } from '../../api/reports.js';
import useBus, { dispatch } from 'use-bus';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import Table from 'components/Table';
import Box from '@mui/material/Box';
import _ from 'lodash';

const defaultPagination = {
	perPage: 5,
	page: 1,
	total_items: 10
};

export default function InstanceTable({ headers, actions }) {
	const [getReports, { data, isLoading }] = useGetReportsMutation();
	const { items, total_amount, ...pagination } = data ?? {};
	const stateDispatch = useDispatch();

	useEffect(() => {
		getReports(defaultPagination);
	}, []);

	useBus(
		'refetch',
		() => {
			getReports(defaultPagination);
		},
		[]
	);

	return (
		<Table
			data={{
				loading: isLoading,
				headers,
				export_file: {
					type: 'EXCEL',
					title: 'Reports'
				},
				rows: {
					items: items?.map((row) => ({
						...row,
						amount: parseInt(
							_.get(row, 'amount', 0) === null ? 0 : _.get(row, 'amount', 0)
						).toLocaleString('en-PH', {
							style: 'currency',
							currency: 'PHP'
						})
					}))
				},
				paginate: {
					...defaultPagination,
					...pagination,
					callback: (pagination) => {
						getReports(pagination);
					}
				},
				add_button: {
					show: false,
					text: '+ Add Serial Number',
					callback: () => {
						dispatch('modal/serial-numbers');
						stateDispatch(setForm(defaultState));
					}
				},
				menu: {
					show: false,
					callback: (menu) => {
						dispatch('modal/serial-numbers');
						stateDispatch(setForm(menu));
					}
				},
				search: {
					show: true,
					callback: (keyword) => {
						getReports({
							...defaultPagination,
							search: keyword
						});
					}
				},
				footer: data?.total_amount && (
					<Box py={2} display="flex" gap={1}>
						<Box fontWeight={'bold'}>Total : </Box>
						{parseInt(data?.total_amount).toLocaleString('en-PH', {
							style: 'currency',
							currency: 'PHP'
						})}
					</Box>
				)
			}}
		/>
	);
}
