import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient.js';

export const serialNumberApi = createApi({
	reducerPath: 'serialNumberApi',
	baseQuery,
	tagTypes: ['SerialNumbers'],
	endpoints: (builder) => ({
		getSerialNumber: builder.mutation({
			query: (body) => ({
				url: '/serial-numbers',
				method: 'GET',
				params: body
			})
		}),
		createSerialNumber: builder.mutation({
			query: (body) => ({
				url: '/serial-numbers',
				method: 'POST',
				body
			})
		}),
		updateSerialNumber: builder.mutation({
			query: ({ id, body }) => ({
				url: `/serial-numbers/${id}`,
				method: 'PATCH',
				body
			})
		}),
		deleteSerialNumber: builder.mutation({
			query: (id) => ({
				url: `/serial-numbers/${id}`,
				method: 'DELETE'
			})
		})
	})
});

// Export the hooks as named exports
export const {
	useGetSerialNumberMutation,
	useCreateSerialNumberMutation,
	useUpdateSerialNumberMutation,
	useDeleteSerialNumberMutation
} = serialNumberApi;
