import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useBus, { dispatch } from 'use-bus';
import { useGetUsersMutation } from '../../api/users.js';
import { setForm, defaultState } from '../../reducers/usersSlice.js';
import Table from 'components/Table';

const defaultPagination = {
	perPage: 5,
	page: 1,
	total_items: 10
};

export default function InstanceTable({ headers, actions }) {
	const [getUsers, { data, isLoading }] = useGetUsersMutation();
	const { items, ...pagination } = data ?? {};
	const stateDispatch = useDispatch();

	useEffect(() => {
		getUsers(defaultPagination);
	}, []);

	useBus(
		'refetch',
		() => {
			getUsers(defaultPagination);
		},
		[]
	);

	return (
		<Table
			data={{
				loading: isLoading,
				headers,
				rows: {
					items: items
				},
				paginate: {
					...defaultPagination,
					...pagination,
					callback: (pagination) => {
						stateDispatch(pagination);
					}
				},
				add_button: {
					show: true,
					text: '+ Add User',
					callback: () => {
						dispatch('modal/users');
						stateDispatch(setForm(defaultState));
					}
				},
				menu: {
					show: true,
					items: actions,
					callback: (menu) => {
						dispatch('modal/users');
						stateDispatch(setForm(menu));
					}
				},
				search: {
					show: true,
					callback: (keyword) => {
						getUsers({
							...defaultPagination,
							search: keyword
						});
					}
				}
			}}
		/>
	);
}
