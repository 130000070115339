import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

export default function OnlyUnauth() {
	const { isAuth } = useAuth();
	const location = useLocation();
	const redirectPath = '/invoices';

	if (!isAuth()) {
		return <Outlet />;
	} else {
		return <Navigate to={redirectPath} state={{ from: location }} replace />;
	}
}
