import { createSlice } from '@reduxjs/toolkit';

const modalState = {
	ADD: {
		type: 'ADD',
		title: 'Add Client',
		disableFields: false
	},
	EDIT: {
		type: 'EDIT',
		title: 'Edit Client',
		disableFields: false
	},
	VIEW: {
		type: 'VIEW',
		title: 'View Client',
		disableFields: false
	},
	DELETE: {
		type: 'DELETE',
		title: 'Delete Client',
		disableFields: false
	}
};

const initialState = {
	list: [],
	form: {
		tin: null,
		name: null,
		address: null,
		agent_id: null
	},
	modal: {
		title: 'Add Client',
		disableFields: false
	}
};

const client = createSlice({
	name: 'clients',
	initialState,
	reducers: {
		setForm: (state, { payload }) => {
			const { id, address, tin, name, agent_id } = payload.row;
			state.form = { id, tin, name, address, agent_id };
			state.modal = modalState[payload.option];
		},
		setTableList: (state, { payload }) => {
			state.list = payload;
		}
	}
});

export const defaultState = {
	row: {
		tin: null,
		name: null,
		address: null,
		agent_id: null
	},
	option: 'ADD'
};
export const { setForm, setTableList } = client.actions;
export default client.reducer;
