import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	clearIcon: {
		paddingRight: 0 // Remove the padding-right
	},
	root: {
		'.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
			paddingRight: '0 !important'
		},
		'&.MuiFormHelperText-root.Mui-error': {
			borderRadius: 0,
			color: '#d32f2f!important'
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 0,
			'& fieldset': {
				borderRadius: 0
			},
			'&.Mui-focused fieldset': {
				borderColor: theme.palette.primary.main
			}
		},
		'&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
			{
				paddingRight: 0
			}
	},
	disabled: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 0,
			backgroundColor: '#E0E0E0',
			'& fieldset': {
				borderColor: '#E0E0E0'
			},
			'&.Mui-focused fieldset': {
				border: 'none',
				borderColor: '#E0E0E0',
				transition: '0.2s ease-in-out'
			}
		}
	},
	error: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 0,
			backgroundColor: '#ffeeeb',
			'& fieldset': {
				borderColor: '#d32f2f'
			},
			'&:hover fieldset': {
				borderColor: '#d32f2f'
			},
			'&:focused ': {
				background: '#ffeeeb'
			}
		}
	}
}));

const helperTextStyles = makeStyles((theme) => ({
	root: {
		margin: 4,
		color: 'black'
	},
	error: {
		'&.MuiFormHelperText-root.Mui-error': {
			color: '#d32f2f'
		}
	}
}));

export { useStyles, helperTextStyles };
