import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const clientListApi = createApi({
	reducerPath: 'clientListApi',
	baseQuery,
	tagTypes: ['ClientList'],
	endpoints: (builder) => ({
		getClientList: builder.query({
			query: (body) => ({
				url: '/client/list',
				method: 'GET',
				params: body
			}),
			transformResponse: (response, meta, args) => {
				return response.items.map((item) => ({
					id: item.id,
					agent_code: item.agent_code,
					address: item.address,
					tin: item.tin
				}));
			}
		})
	})
});

// Export the hooks as named exports
export const { useGetClientListQuery } = clientListApi;
