import { useGetClientQuery } from './api/clients';
import { useFormikContext } from 'formik';
import { useEffect, useCallback } from 'react';

import Autocomplete from 'components/Fields/AutoComplete';
import _ from 'lodash';

export default function CustomDropdown({
	label,
	required,
	value,
	...otherProps
}) {
	const { data: options, isLoading: loading, status } = useGetClientQuery();
	const { values, setValues } = useFormikContext();
	const clientId = _.get(values, 'client_id');

	const updateValuesBasedOnClient = useCallback(() => {
		if (status === 'fulfilled' && clientId) {
			const clientDetails = _.find(options, { id: clientId });
			const { agent_code, address, tin } = clientDetails ?? '';

			setValues((prevValues) => ({
				...prevValues,
				agent_code,
				address,
				tin
			}));
		}
	}, [status, clientId, options, setValues]);

	useEffect(() => {
		updateValuesBasedOnClient();
	}, [updateValuesBasedOnClient]); // Include the callback function in the dependency array

	const configDropdownSelect = {
		...otherProps,
		value,
		options,
		loading,
		disabled: loading,
		isRequired: required,
		name: 'client_id',
		label: 'Client'
	};

	return <Autocomplete {...configDropdownSelect} />;
}
