import { Box, LinearProgress } from '@mui/material';

const TableLoaders = ({ loading, children }) => {
	return loading ? (
		<Box my={1}>
			<LinearProgress />
		</Box>
	) : (
		children
	);
};

export default TableLoaders;
