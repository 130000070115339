import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const clientApi = createApi({
	reducerPath: 'clientApi',
	baseQuery,
	tagTypes: ['Client'],
	endpoints: (builder) => ({
		getClient: builder.mutation({
			query: (body) => ({
				url: '/client',
				method: 'GET',
				params: body
			})
		}),
		createClient: builder.mutation({
			query: (body) => ({
				url: '/client',
				method: 'POST',
				body
			})
		}),
		updateClient: builder.mutation({
			query: ({ id, body }) => ({
				url: `/client/${id}`,
				method: 'PATCH',
				body
			})
		}),
		deleteClient: builder.mutation({
			query: (id) => ({
				url: `/client/${id}`,
				method: 'DELETE'
			})
		})
	})
});

// Export the hooks as named exports
export const {
	useGetClientMutation,
	useCreateClientMutation,
	useUpdateClientMutation,
	useDeleteClientMutation
} = clientApi;
