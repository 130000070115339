import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { useCallback, useEffect, useRef } from 'react';
import { ValidationSchema } from './utils/validation';
import { FIELDS } from './constants/fields';
import useBus, { dispatch } from 'use-bus';
import { useSelector } from 'react-redux';
import {
	useCreateInvoicesMutation,
	useUpdateInvoicesMutation,
	useDeleteInvoicesMutation,
	useVoidInvoicesMutation
} from '../../api/invoices';

import SerialNumbers from 'reusables/fields/SerialNumbersDropdown';
import CompanyDropdown from 'reusables/fields/CompanyDropdown';
import FormikWrapper from 'components/Form/FormikWrapper';
import FormikForm from 'components/Form/Formik';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const FormikCompanyDropdown = FormikWrapper(CompanyDropdown);
const FormikSerialNumbers = FormikWrapper(SerialNumbers);
const DISPATCH_NAME = 'modal/serial-numbers';
const FORM_TYPES = {
	ADD: 'ADD',
	EDIT: 'EDIT',
	VIEW: 'VIEW',
	DELETE: 'DELETE',
	VOID: 'VOID'
};

export default function Form({ eventName }) {
	const modal = useSelector((state) => state.invoices.modal);
	const form = useSelector((state) => state.invoices.form);
	const formRef = useRef();

	const { setSnackBarMessage } = useSnackBarMessage();
	const onViewModal = modal.type === FORM_TYPES.VIEW;
	const [voidInvoices, { status: VoidStatus }] = useVoidInvoicesMutation();
	const [createInvoices, { status: AddStatus, error: AddError }] =
		useCreateInvoicesMutation();
	const [updateInvoices, { status: EditStatus, error: EditError }] =
		useUpdateInvoicesMutation();
	const [deleteInvoices, { status: DeleteStatus }] =
		useDeleteInvoicesMutation();

	const memoizedStatusDispatcher = useCallback(() => {
		if (
			AddStatus === 'fulfilled' ||
			EditStatus === 'fulfilled' ||
			DeleteStatus === 'fulfilled' ||
			VoidStatus === 'fulfilled'
		) {
			setSnackBarMessage('Succesfully', STATUS.SUCCESS);
			dispatch(DISPATCH_NAME);
			dispatch('refetch');
		}
	}, [setSnackBarMessage, AddStatus, EditStatus, DeleteStatus, VoidStatus]);

	useEffect(() => {
		memoizedStatusDispatcher();
	}, [memoizedStatusDispatcher]);

	useBus(
		eventName,
		() => {
			if (formRef.current) {
				formRef.current.handleSubmit();
			}
			if (modal.type === FORM_TYPES.DELETE) {
				const { invoice_id: id } = form;
				deleteInvoices(id);
			}
		},
		[formRef]
	);

	const handleFormSubmit = (values) => {
		if (modal.type === FORM_TYPES.ADD) {
			createInvoices(values);
		}
		if (modal.type === FORM_TYPES.EDIT) {
			const { invoice_id: id, ...payload } = values;
			updateInvoices({ id, body: payload });
		}
		if (modal.type === FORM_TYPES.VOID) {
			const { invoice_id: id, ...payload } = values;
			voidInvoices({ id, body: payload });
		}
	};

	return (
		<FormikForm
			setErrors={{
				...AddError?.data?.error,
				...EditError?.data?.error
			}}
			formName={eventName}
			innerRef={formRef}
			initialValues={form}
			enableReinitialize={true}
			validationSchema={ValidationSchema}
			onSubmit={(values) => handleFormSubmit(values)}
		>
			<Stack spacing={2}>
				<Box>
					<h2>Company Details</h2>
					<Divider />
				</Box>
				<FormikCompanyDropdown readOnly={true} />
				{FIELDS.map((field) => (
					<>
						<h3>{field.header}</h3>
						<Divider />

						<Stack
							direction={{ xs: 'column', sm: 'row' }}
							spacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<FormikSerialNumbers
								label="Current"
								readOnly={true}
								type={field.type}
								name={field.current.name}
							/>
							<FormikSerialNumbers
								label="New"
								readOnly={onViewModal}
								type={field.type}
								name={field.new.name}
								isNew={true}
							/>
						</Stack>
					</>
				))}
			</Stack>
		</FormikForm>
	);
}
