import { createSlice } from '@reduxjs/toolkit';
import currency from 'currency.js';

const modalState = {
	ADD: {
		type: 'ADD',
		title: 'Add Company',
		disableFields: false
	},
	EDIT: {
		type: 'EDIT',
		title: 'Edit Company',
		disableFields: false
	},
	VIEW: {
		type: 'VIEW',
		title: 'View Company',
		disableFields: false
	},
	DELETE: {
		type: 'DELETE',
		title: 'Delete Company',
		disableFields: false
	}
};

const initialState = {
	form: {
		name: null,
		code: null,
		amount_limit: null
	},
	modal: {
		title: 'Add Company',
		disableFields: false
	}
};

const peso = (value) =>
	currency(value, { symbol: '', separator: ',', decimal: '.' });

const company = createSlice({
	name: 'company',
	initialState,
	reducers: {
		setForm: (state, { payload }) => {
			const { id, name, code, amount_limit, amount_decimal } = payload.row;
			state.form = {
				id,
				name,
				code,
				amount_limit: peso(amount_limit).format(),
				amount_decimal
			};
			state.modal = modalState[payload.option];
		}
	}
});

export const defaultState = {
	row: {
		name: null,
		code: null,
		amount_limit: null
	},
	option: 'ADD'
};
export const { setForm } = company.actions;
export default company.reducer;
