import { useState } from 'react';
import { useStyles } from 'components/Table/styles/table';
import { exportExcel } from './helper/export.excel';
import { EXPORT_OPTIONS } from './constants/table.constants';

import {
	Box,
	Grid,
	Table,
	TableContainer,
	TablePagination
} from '@mui/material';

import TableHeader from './components/TableHeader';
import TableBody from './components/TableBody';
import TableCardLayout from './components/TableCardLayout';
import TableAddButton from './components/TableAddButton';
import TableSearchField from './components/TableSearchField';
import TableExportMenu from './components/TableExportMenu';
import TableLoader from './components/TableLoader';
import { debounce } from 'lodash';

const TableList = ({ data }) => {
	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [index, setIndex] = useState(0);
	const [isSearch, setIsSearch] = useState(false);
	const [perPage, setRowsPerPage] = useState(data?.paginate?.perPage);

	const searchKeyword = debounce((keyword) => {
		if (data.search.hasOwnProperty('callback')) {
			data?.search?.callback(keyword);
			setIsSearch(keyword !== '');
		}
	}, 1000);

	const handleChangePage = (event, newPage) => {
		if (data?.paginate?.callback instanceof Function) {
			data?.paginate?.callback({
				page: newPage + 1,
				perPage
			});
		}

		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
		data?.paginate?.callback({
			page: 1,
			perPage: parseInt(event.target.value, 10)
		});
	};

	const handleMenuDownload = () => {
		if (data?.export_file.type === 'EXCEL') {
			exportExcel(
				data?.export_file?.rows || data?.rows?.items,
				data?.headers || data?.export_file?.headers,
				data?.export_file.title
			);
		}
	};

	return (
		<TableCardLayout>
			{data?.filter?.show && (
				<Grid item xs={12}>
					<Box
						my={1}
						display="flex"
						style={{
							minWidth: 1178,
							maxWidth: 50,
							overflowX: 'scroll'
						}}
					>
						{data?.filter?.items?.map((d, key) => (
							<Box
								my={1}
								mx={2}
								fontWeight="600"
								key={`tbl-filter-${key}`}
								style={
									key === index
										? { color: '#FCDC13', borderBottom: '3px solid' }
										: {}
								}
							>
								<span
									style={{ cursor: 'pointer', color: 'black' }}
									onClick={() => {
										setIndex(key);
										data?.filter?.callback(d.value);
									}}
								>
									{d?.text}
								</span>
							</Box>
						))}
					</Box>
					{data?.filter?.components}
				</Grid>
			)}
			<Grid
				container
				direction="row"
				justify="space-between"
				alignItems="stretch"
			>
				<Grid
					item
					xs={12}
					sm={12}
					md={data?.add_button?.show ? 8 : 12}
					lg={data?.add_button?.show ? 9 : 12}
					xl={data?.add_button?.show ? 10 : 12}
				>
					{data?.hasOwnProperty('search') && (
						<Box my={1} mx={1}>
							<TableSearchField
								isSearching={data?.loading}
								keywords={(keyword) => {
									searchKeyword(keyword);
								}}
							/>
						</Box>
					)}
				</Grid>
				<Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
					{data?.hasOwnProperty('add_button') && (
						<Box my={1} mx={1}>
							<TableAddButton
								show={data?.add_button?.show}
								handleClick={() => {
									if (data.add_button.hasOwnProperty('callback')) {
										data.add_button.callback();
									}
								}}
								ButtonText={data?.add_button?.text}
							/>
						</Box>
					)}
				</Grid>
			</Grid>
			<Box className={classes.download}>
				<Box mx={2}>
					{data?.hasOwnProperty('export_file') && (
						<TableExportMenu
							items={EXPORT_OPTIONS}
							handleAction={(e) => handleMenuDownload()}
						/>
					)}
				</Box>
				<Box mx={2} className={classes.tableToolbar}>
					<TablePagination
						page={page}
						component={'div'}
						rowsPerPage={perPage}
						onPageChange={handleChangePage}
						count={data?.paginate?.total_items}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={data?.rowsPerPageOptions || [5, 10, 25, 50]}
						backIconButtonProps={
							data?.loading
								? {
										disabled: data?.loading
								  }
								: undefined
						}
						nextIconButtonProps={
							data?.loading
								? {
										disabled: data?.loading
								  }
								: undefined
						}
					/>
				</Box>
			</Box>

			<TableContainer>
				<TableLoader loading={data?.loading}>
					<Table
						className={classes.table}
						aria-label="enhanced table"
						aria-labelledby="tableTitle"
					>
						<TableHeader items={data?.headers} menu={data?.menu} />
						<TableBody
							items={data?.rows?.items}
							headers={data?.headers}
							menu={data?.menu}
						/>
					</Table>
					{data?.rows?.items?.length === 0 && (
						<div
							style={{
								height: '35vh',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column'
							}}
						>
							<h2>{isSearch ? 'No Results Found' : 'No Data to Show'}</h2>
							<span>
								{isSearch
									? 'Try adjusting your keyword to find what you are looking for.'
									: 'Please add a data to display it here.'}
							</span>
						</div>
					)}
				</TableLoader>
			</TableContainer>
			{data?.footer}
		</TableCardLayout>
	);
};

TableList.defaultProps = {
	rows: [],
	headers: []
};

export default TableList;
