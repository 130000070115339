import * as Yup from 'yup';

const NAME_PATTERN = /^(?=.{2,50}$)[a-zÀ-ž.]+(?:['-_\.\s][a-zÀ-ž]+)*$/i;
const EMAIL_PATTERN = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const ValidationSchema = Yup.object().shape({
	fname: Yup.string()
		.required('First Name is required')
		.matches(
			NAME_PATTERN,
			'First Name must be minimum of 2 letters and contain only letters'
		)
		.nullable(),
	mname: Yup.string()
		.matches(
			NAME_PATTERN,
			'Middle Name must be minimum of 2 letters and contain only letters'
		)
		.nullable(),
	lname: Yup.string()
		.required('Last Name is required')
		.matches(
			NAME_PATTERN,
			'Last Name must be minimum of 2 letters and contain only letters'
		)
		.nullable(),
	suffix: Yup.string()
		.matches(/^[A-Za-z]{0,4}$/gi, 'Suffix must be valid!')
		.nullable(),
	email: Yup.string()
		.matches(EMAIL_PATTERN, 'Email Address must be valid!')
		.required('Email Address is required')
		.nullable(),
	password: Yup.string()
		.when('isEdit', {
			is: false,
			then: Yup.string()
				.matches(/^\w{8,50}$/, 'Password required!')
				.nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable(),
	confirmPassword: Yup.string()
		.when('isEdit', {
			is: false,
			then: Yup.string()
				.matches(/^\w{8,50}$/, 'Re-enter Confirm Password is required!')
				.nullable(),
			otherwise: Yup.string().nullable()
		})
		.nullable()
});
