import {
	setForm,
	setTableList,
	defaultState
} from '../../reducers/clientSlice';
import { useGetClientMutation } from '../../api/clients';
import useBus, { dispatch } from 'use-bus';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import Table from 'components/Table';

const defaultPagination = {
	perPage: 5,
	page: 1,
	total_items: 10
};

export default function InstanceTable({ headers, actions }) {
	const [getClient, { data, isLoading }] = useGetClientMutation();
	const { items, ...pagination } = data ?? {};
	const stateDispatch = useDispatch();

	useEffect(() => {
		getClient(defaultPagination);
	}, []);

	useBus(
		'refetch',
		() => {
			getClient(defaultPagination);
		},
		[]
	);

	useEffect(() => {
		stateDispatch(setTableList(items));
	}, [items]);

	return (
		<Table
			data={{
				loading: isLoading,
				headers,
				rows: { items },
				paginate: {
					...defaultPagination,
					...pagination,
					callback: (pagination) => {
						getClient(pagination);
					}
				},
				add_button: {
					show: true,
					text: '+ Add Client',
					callback: () => {
						dispatch('modal/clients');
						stateDispatch(setForm(defaultState));
					}
				},
				menu: {
					show: true,
					items: actions,
					callback: (menu) => {
						dispatch('modal/clients');
						stateDispatch(setForm(menu));
					}
				},
				search: {
					show: true,
					callback: (keyword) => {
						getClient({
							...defaultPagination,
							search: keyword
						});
					}
				}
			}}
		/>
	);
}
