import { createContext, useState, useEffect } from 'react';
export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
	const token = localStorage.getItem('uat');
	const isAuth = () => (token ? true : false);

	const [auth, setAuth] = useState({
		isAuthenticated: false
	});

	const setLogout = () => {
		localStorage.removeItem('uat');
		window.location.reload();
	};

	useEffect(() => {
		return () => setAuth({});
	}, []);

	const values = {
		auth,
		isAuth,
		setAuth,
		setLogout
	};

	return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
