import { useGetAgentsQuery } from './api/agents';
import Autocomplete from 'components/Fields/AutoComplete';

export default function CustomDropdown({
	label,
	required,
	value,
	...otherProps
}) {
	const { data: options, isLoading: loading } = useGetAgentsQuery();
	const configDropdownSelect = {
		...otherProps,
		value,
		options,
		loading,
		disabled: loading,
		isRequired: required,
		name: 'agent_id',
		label: 'Agent'
	};

	return <Autocomplete {...configDropdownSelect} />;
}
