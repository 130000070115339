import { createSlice } from '@reduxjs/toolkit';
import currency from 'currency.js';

const modalState = {
	ADD: {
		type: 'ADD',
		title: 'Add Invoices',
		disableFields: false
	},
	EDIT: {
		type: 'EDIT',
		title: 'Edit Invoices',
		disableFields: false
	},
	VIEW: {
		type: 'VIEW',
		title: 'View Invoices',
		disableFields: false
	},
	VOID: {
		type: 'VOID',
		title: 'Void Invoices',
		disableFields: false
	},
	DELETE: {
		type: 'DELETE',
		title: 'Delete Invoices',
		disableFields: false
	}
};

const initialState = {
	form: {
		company_id: null,
		si_id: null,
		cr_id: null,
		or_id: null,
		sid_id: null,
		client_id: null
	},
	modal: {
		type: 'ADD',
		title: 'Add Invoices',
		disableFields: false
	}
};

const peso = (value) =>
	currency(value, { symbol: '', separator: ',', decimal: '.' });

const invoices = createSlice({
	name: 'invoices',
	initialState,
	reducers: {
		setForm: (state, { payload }) => {
			const { id, amount, ...params } = payload.row;
			state.form = {
				id,
				...params,
				amount_non_format: amount,
				amount: peso(amount).format()
			};
			state.modal = modalState[payload.option];
		}
	}
});

export const defaultState = {
	row: {
		company_id: null,
		client_id: null,
		si_id: null,
		or_id: null,
		cr_id: null,
		sid_id: null
	},
	option: 'ADD'
};
export const { setForm } = invoices.actions;
export default invoices.reducer;
