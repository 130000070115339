import { useStyles, helperTextStyles } from './style';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorIcon from '@mui/icons-material/Error';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

import ClearButton from '../FieldClearButton';
import FieldLabel from '../FieldLabel';

import clsx from 'clsx';
import _ from 'lodash';

export default function CustomTextField({
	name,
	label,
	icon,
	required,
	disabled,
	readOnly = false,
	handleChange,
	setFieldValue,
	errors,
	value,
	...otherProps
}) {
	const classes = useStyles();
	const helperTextClasses = helperTextStyles();
	let configFieldLabel = {
		text: label,
		required
	};

	let configTextField = {
		...otherProps,
		value,
		disabled,
		size: 'small',
		variant: 'outlined',
		fullWidth: true,
		FormHelperTextProps: { classes: helperTextClasses },
		className: clsx({
			[classes.root]: !_.has(errors, name) && !disabled,
			[classes.error]: _.has(errors, name) && !disabled,
			[classes.disabled]: disabled
		}),
		InputProps: {
			readOnly,
			endAdornment:
				value && !disabled && !readOnly ? (
					<ClearButton
						handleClick={(e) => {
							if (handleChange instanceof Function) {
								handleChange(e.target.value);
							} else if (setFieldValue instanceof Function) {
								setFieldValue(name, '');
							}
						}}
					/>
				) : (
					_.has(errors, name) &&
					!disabled && <ErrorIcon style={{ fontSize: 16, color: '#d32f2f' }} />
				),
			startAdornment: <InputAdornment position="start">{icon}</InputAdornment>
		}
	};

	//when there's an error on errors obj
	if (_.has(errors, name) && !disabled) {
		configFieldLabel.error = true;
		configTextField.error = true;
		configTextField.helperText = _.get(errors, name);
	}

	return (
		<Box display="flex" flexDirection="column" flex={1}>
			<FieldLabel {...configFieldLabel} />
			<TextField {...configTextField} />
		</Box>
	);
}
