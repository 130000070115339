import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { useEffect, useRef, useCallback } from 'react';
import { ValidationSchema } from './utils/validation';
import { getTypeAction } from 'utils/actionTypes';

import useBus, { dispatch } from 'use-bus';
import { useSelector } from 'react-redux';
import {
	useCreateInvoicesMutation,
	useUpdateInvoicesMutation,
	useDeleteInvoicesMutation
} from '../../api/invoices';

import SerialNumbers from 'reusables/fields/SerialNumbersDropdown';
import DecimalTextField from 'reusables/fields/DecimalTextField';
import CompanyDropdown from 'reusables/fields/CompanyDropdown';
import ClientDropdown from 'reusables/fields/ClientDropdown';
import FormikWrapper from 'components/Form/FormikWrapper';
import ClientField from 'reusables/fields/ClientField';
import DatePicker from 'components/Fields/DatePicker';
import FormikForm from 'components/Form/Formik';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

const FormikCompanyDropdown = FormikWrapper(CompanyDropdown);
const FormikClientDropdown = FormikWrapper(ClientDropdown);
const FormikSerialNumbers = FormikWrapper(SerialNumbers);
const FormikTextField = FormikWrapper(DecimalTextField);
const FormikClientField = FormikWrapper(ClientField);
const DISPATCH_NAME = 'modal/invoices';
const FORM_TYPES = {
	ADD: 'ADD',
	EDIT: 'EDIT',
	VIEW: 'VIEW',
	DELETE: 'DELETE'
};

export default function Form({ eventName }) {
	const modal = useSelector((state) => state.invoices.modal);
	const form = useSelector((state) => state.invoices.form);
	const formRef = useRef();

	const { setSnackBarMessage } = useSnackBarMessage();
	const [createInvoices, { status: AddStatus, error: AddError }] =
		useCreateInvoicesMutation();
	const [updateInvoices, { status: EditStatus, error: EditError }] =
		useUpdateInvoicesMutation();
	const [deleteInvoices, { status: DeleteStatus }] =
		useDeleteInvoicesMutation();
	const onViewModal = modal.type === FORM_TYPES.VIEW;

	const memoizedStatusDispatcher = useCallback(() => {
		if (
			AddStatus === 'fulfilled' ||
			EditStatus === 'fulfilled' ||
			DeleteStatus === 'fulfilled'
		) {
			setSnackBarMessage(
				`Succesfully ${getTypeAction(modal.type)}`,
				STATUS.SUCCESS
			);
			dispatch(DISPATCH_NAME);
			dispatch('refetch');
		}
	}, [AddStatus, EditStatus, DeleteStatus, setSnackBarMessage, modal.type]);

	useEffect(() => {
		memoizedStatusDispatcher();
	}, [memoizedStatusDispatcher]);

	useBus(
		eventName,
		() => {
			if (formRef.current) {
				formRef.current.handleSubmit();
			}
			if (modal.type === FORM_TYPES.DELETE) {
				const { invoice_id: id } = form;
				deleteInvoices(id);
			}
		},
		[formRef]
	);

	const handleFormSubmit = (values) => {
		if (modal.type === FORM_TYPES.ADD) {
			createInvoices(values);
		}
		if (modal.type === FORM_TYPES.EDIT) {
			const { invoice_id: id, ...payload } = values;
			updateInvoices({ id, body: payload });
		}
	};

	return modal.type !== FORM_TYPES.DELETE ? (
		<FormikForm
			name="formik"
			setErrors={{
				...AddError?.data?.error,
				...EditError?.data?.error
			}}
			formName={eventName}
			innerRef={formRef}
			initialValues={form}
			enableReinitialize={true}
			validationSchema={ValidationSchema}
			onSubmit={(values) => handleFormSubmit(values)}
		>
			<Stack spacing={2}>
				<DatePicker
					label="Date Issued"
					name="date_issued"
					readOnly={onViewModal}
				/>
				<FormikTextField label="Amount" name="amount" readOnly={onViewModal} />
				<Box>
					<h2>Company Details</h2>
					<Divider />
				</Box>
				<FormikCompanyDropdown readOnly={onViewModal} />
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					spacing={{ xs: 1, sm: 2, md: 3 }}
				>
					<FormikSerialNumbers
						label="Sale Invoice No."
						readOnly={onViewModal}
						type="SI"
						name="si"
					/>
					<FormikSerialNumbers
						label="Collection Receipt No."
						readOnly={onViewModal}
						type="CR"
						name="cr"
					/>
					<FormikSerialNumbers
						label="Official Receipt No."
						readOnly={onViewModal}
						type="OR"
						name="or"
					/>
					<FormikSerialNumbers
						label="Service Invoice"
						readOnly={onViewModal}
						type="SID"
						name="sid"
					/>
				</Stack>
				<Box>
					<h2>Client Details</h2>
					<Divider />
				</Box>
				<FormikClientDropdown readOnly={onViewModal} />
				<Stack
					direction={{ xs: 'column', sm: 'row' }}
					spacing={{ xs: 1, sm: 2, md: 3 }}
				>
					<FormikClientField label="TIN" name="tin" readOnly />
					<FormikClientField label="Address" name="address" readOnly />
					<FormikClientField label="Agent Code" name="agent_code" readOnly />
				</Stack>
				<Box py={2}>
					<Divider />
				</Box>
			</Stack>
		</FormikForm>
	) : (
		<>Do you want to delete this Invoice ? </>
	);
}
