import useStyles from './styles';
import { Button } from '@mui/material';

const CustomButton = ({ children, variant, ...otherProps }) => {
	const classes = useStyles();

	const configButton = {
		disableElevation: true,
		variant: variant,
		color: 'primary',
		className: classes.outlinedButton,
		...otherProps
	};

	return <Button {...configButton}>{children}</Button>;
};

export default CustomButton;
