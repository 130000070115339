import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { useEffect, useRef, useCallback } from 'react';
import { ValidationSchema } from './utils/validation';
import { getTypeAction } from 'utils/actionTypes';
import useBus, { dispatch } from 'use-bus';
import { useSelector } from 'react-redux';
import {
	useCreateClientMutation,
	useUpdateClientMutation,
	useDeleteClientMutation
} from './../../api/clients';

import AgentDropdown from 'reusables/fields/AgentDropdown';
import CustomTextField from 'components/Fields/TextField';
import FormikWrapper from 'components/Form/FormikWrapper';
import FormikForm from 'components/Form/Formik';
import Stack from '@mui/material/Stack';

const FormikAgentDropdown = FormikWrapper(AgentDropdown);
const FormikTextField = FormikWrapper(CustomTextField);
const DISPATCH_NAME = 'modal/clients';
const FORM_TYPES = {
	ADD: 'ADD',
	EDIT: 'EDIT',
	VIEW: 'VIEW',
	DELETE: 'DELETE'
};

export default function Form({ eventName }) {
	const modal = useSelector((state) => state.clients.modal);
	const form = useSelector((state) => state.clients.form);
	const formRef = useRef();

	const { setSnackBarMessage } = useSnackBarMessage();
	const [createClient, { status: AddStatus, error: AddError }] =
		useCreateClientMutation();
	const [updateClient, { status: EditStatus, error: EditError }] =
		useUpdateClientMutation();
	const [deleteClient, { status: DeleteStatus }] = useDeleteClientMutation();
	const onViewModal = modal.type === FORM_TYPES.VIEW;

	const memoizedStatusDispatcher = useCallback(() => {
		if (
			AddStatus === 'fulfilled' ||
			EditStatus === 'fulfilled' ||
			DeleteStatus === 'fulfilled'
		) {
			setSnackBarMessage(
				`Succesfully ${getTypeAction(modal.type)}`,
				STATUS.SUCCESS
			);
			dispatch(DISPATCH_NAME);
			dispatch('refetch');
		}
	}, [AddStatus, EditStatus, DeleteStatus, setSnackBarMessage, modal.type]);

	useEffect(() => {
		memoizedStatusDispatcher();
	}, [memoizedStatusDispatcher]);

	useBus(
		eventName,
		() => {
			if (formRef.current) {
				formRef.current.handleSubmit();
			}
			if (modal.type === FORM_TYPES.DELETE) {
				const { id } = form;
				deleteClient(id);
			}
		},
		[formRef]
	);

	const handleFormSubmit = (values) => {
		if (modal.type === FORM_TYPES.ADD) {
			createClient(values);
		}
		if (modal.type === FORM_TYPES.EDIT) {
			const { id, ...payload } = values;
			updateClient({ id, body: payload });
		}
	};

	return modal.type !== FORM_TYPES.DELETE ? (
		<FormikForm
			setErrors={{ ...AddError?.data?.error, ...EditError?.data?.error }}
			innerRef={formRef}
			formName={eventName}
			initialValues={form}
			enableReinitialize={true}
			validationSchema={ValidationSchema}
			onSubmit={(values) => handleFormSubmit(values)}
		>
			<Stack spacing={2}>
				<FormikTextField label="Name" name="name" readOnly={onViewModal} />
				<FormikTextField label="TIN" name="tin" readOnly={onViewModal} />
				<FormikTextField
					label="Address"
					name="address"
					readOnly={onViewModal}
				/>
				<FormikAgentDropdown readOnly={onViewModal} />
			</Stack>
		</FormikForm>
	) : (
		<>Do you want to delete this Client ? </>
	);
}
