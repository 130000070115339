import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { useEffect, useCallback, useRef } from 'react';
import { ValidationSchema } from './utils/validation';
import { getTypeAction } from 'utils/actionTypes';
import useBus, { dispatch } from 'use-bus';
import { useSelector } from 'react-redux';
import {
	useCreateCompanyMutation,
	useUpdateCompanyMutation,
	useDeleteCompanyMutation
} from './../../api/companies';

import DecimalTextField from 'reusables/fields/DecimalTextField';
import CustomTextField from 'components/Fields/TextField';
import FormikWrapper from 'components/Form/FormikWrapper';
import FormikForm from 'components/Form/Formik';
import Stack from '@mui/material/Stack';

const FormikDecimalTextfield = FormikWrapper(DecimalTextField);
const FormikTextField = FormikWrapper(CustomTextField);
const DISPATCH_NAME = 'modal/companies';
const FORM_TYPES = {
	ADD: 'ADD',
	EDIT: 'EDIT',
	VIEW: 'VIEW',
	DELETE: 'DELETE'
};

export default function Form({ eventName }) {
	const form = useSelector((state) => state.company.form);
	const modal = useSelector((state) => state.company.modal);
	const formRef = useRef();

	const { setSnackBarMessage } = useSnackBarMessage();
	const [createComapny, { status: AddStatus, error: AddError }] =
		useCreateCompanyMutation();
	const [updateCompany, { status: EditStatus, error: EditError }] =
		useUpdateCompanyMutation();
	const [deleteCompany, { status: DeleteStatus }] = useDeleteCompanyMutation();
	const onViewModal = modal.type === FORM_TYPES.VIEW;

	const memoizedStatusDispatcher = useCallback(() => {
		if (
			AddStatus === 'fulfilled' ||
			EditStatus === 'fulfilled' ||
			DeleteStatus === 'fulfilled'
		) {
			setSnackBarMessage(
				`Succesfully ${getTypeAction(modal.type)}`,
				STATUS.SUCCESS
			);
			dispatch(DISPATCH_NAME);
			dispatch('refetch');
		}
	}, [AddStatus, EditStatus, DeleteStatus, setSnackBarMessage, modal.type]);

	useEffect(() => {
		memoizedStatusDispatcher();
	}, [memoizedStatusDispatcher]);

	useBus(
		eventName,
		() => {
			if (formRef.current) {
				formRef.current.handleSubmit();
			}
			if (modal.type === FORM_TYPES.DELETE) {
				const { id } = form;
				deleteCompany(id);
			}
		},
		[formRef]
	);

	const handleFormSubmit = (values) => {
		if (modal.type === FORM_TYPES.ADD) {
			createComapny(values);
		}
		if (modal.type === FORM_TYPES.EDIT) {
			const { id, ...payload } = values;
			updateCompany({ id, body: payload });
		}
	};

	return modal.type !== FORM_TYPES.DELETE ? (
		<FormikForm
			setErrors={{
				...AddError?.data?.error,
				...EditError?.data?.error
			}}
			formName={eventName}
			innerRef={formRef}
			initialValues={form}
			enableReinitialize={true}
			validationSchema={ValidationSchema}
			onSubmit={(values) => handleFormSubmit(values)}
		>
			<Stack spacing={2}>
				<FormikTextField label="Name" name="name" readOnly={onViewModal} />
				<FormikTextField label="Code" name="code" readOnly={onViewModal} />
				<FormikDecimalTextfield
					label="Amount Limit"
					name="amount_limit"
					readOnly={onViewModal}
				/>
			</Stack>
		</FormikForm>
	) : (
		<>Do you want to delete this Company ? </>
	);
}
