import { TABLE_ACTION_MENU } from 'components/Table/constants/table.constants';
import { TABLE_HEADERS } from './constants/table';
import { Table, Modal, Form } from './components';

import Box from '@mui/material/Box';

const EVENT_NAME = 'submit/company-form';

export default function Companies() {
	return (
		<Box>
			<Modal eventName={EVENT_NAME}>
				<Form eventName={EVENT_NAME} />
			</Modal>
			<Table headers={TABLE_HEADERS} actions={TABLE_ACTION_MENU} />
		</Box>
	);
}
