import { useSelector } from 'react-redux';
import { dispatch } from 'use-bus';

import Modal from 'components/Modal';

export default function InstanceModal({ children, eventName }) {
	const modal = useSelector((state) => state.clients.modal);
	const onViewModal = modal.type === 'VIEW';

	return (
		<Modal
			name="clients"
			title={modal.title}
			content={children}
			actions={{
				show: true,
				left: {
					text: 'Discard',
					disabled: false,
					callback: function () {
						dispatch('modal/clients');
					}
				},
				right: {
					text: 'Submit',
					disabled: onViewModal,
					callback: function () {
						dispatch(eventName);
					}
				}
			}}
		/>
	);
}
