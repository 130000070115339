import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%'
	},
	search: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: 400,
		backgroundColor: '#EDEEEE'
	},
	'&.MuiTablePagination-input': {
		backgroundColor: 'red'
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1
	},
	typoGraphy: {
		fontWeight: 800,
		fontSize: '10px',
		color: '#87898C'
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1
	},
	iconButton: {
		padding: 10
	},
	divider: {
		height: 28,
		margin: 4
	},
	formControl: {
		'& .MuiInputLabel-outlined': {
			color: 'green'
		},
		'&:hover .MuiInputLabel-outlined': {
			color: 'red'
		},
		backgroundColor: '#EDEEEE'
	},
	header: {
		fontWeight: '750',
		fontSize: '12px',
		color: '#656565'
	},
	download: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	downloadBtn: {
		color: '#87898C',
		fontWeight: '700',
		fontSize: '14px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		textDecoration: 'none'
	},
	tableToolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

export { useStyles };
