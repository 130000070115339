import { createTheme } from '@mui/material/styles';

export const customTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1366 // Add a custom breakpoint for 1366px width
		}
	},
	button: {
		view: '#E2E2E2'
	},
	typography: {
		fontFamily: [
			'Manjari',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		].join(',')
	},
	palette: {
		primary: {
			main: '#074195',
			light: '#38BE6C'
		},
		neutral: {
			main: '#E2E2E2',
			contrastText: '#757575'
		}
	}
});
