import React from 'react';
import { useFormikContext } from 'formik';

const FormikWrapper = (FormField) => {
	return ({ name, ...props }) => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { setFieldValue, values, errors, ...submitForm } = useFormikContext();
		const config = {
			...props,
			name,
			errors,
			values,
			setFieldValue,
			value: values[name] ?? null,
			submitForm,
			onChange: (e) => {
				setFieldValue(name, e.target.value);
			}
		};

		return <FormField {...config} />;
	};
};

export default FormikWrapper;
