import { ReactComponent as Logout } from '../../../assets/svg/Logout.svg';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles/drawer';
import { useAuth } from 'hooks/useAuth';
import React, { memo } from 'react';

import logo from '../../../assets/images/inventory-logo.png';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Avatar from 'components/Avatar';
import Box from '@mui/material/Box';
import moment from 'moment';
import clsx from 'clsx';

export default memo(function PermanentDrawerLeft({ children, state }) {
	const navigate = useNavigate();
	const classes = useStyles();
	const { auth, setLogout } = useAuth();

	const handleNavigate = (path) => {
		navigate(path);
	};

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			className={clsx({
				[classes.drawerOpen]: state,
				[classes.drawerClose]: !state
			})}
		>
			<Toolbar />
			<Divider />
			<Box className={classes.drawerContainer}>
				<Box
					className={clsx({
						[classes.brandLogoOpen]: state,
						[classes.brandLogoClose]: !state
					})}
				>
					<Typography variant="body2" component="span" color="#7E7E7E">
						{moment().format('dddd')},&nbsp;
					</Typography>
					<Typography variant="body2" component="span" color="primary">
						{moment().format('MMMM DD, YYYY')}
					</Typography>
				</Box>
				<Box
					sx={{ cursor: 'pointer' }}
					onClick={() => {
						handleNavigate('/main');
					}}
				>
					<Avatar width={70} height={70} logo={logo} />
				</Box>
				<Box
					className={clsx({
						[classes.brandLogoOpen]: state,
						[classes.brandLogoClose]: !state
					})}
				>
					<Typography variant="button" component="span" color="primary">
						{auth?.fname}
					</Typography>
				</Box>
			</Box>
			<Box
				justifyContent="space-between"
				backgroundColor="#074195"
				flexDirection="column"
				id="container"
				height="100vh"
				display="flex"
				color="#FFF"
			>
				{children}
				<List>
					<ListItem onClick={() => setLogout()}>
						<ListItemIcon
							style={{
								justifyContent: 'center'
							}}
						>
							<Logout />
						</ListItemIcon>
						<ListItemText
							style={{
								margin: 0,
								color: state ? '#FFF' : '#074195'
							}}
							primary={'Logout'}
						/>
					</ListItem>
				</List>
			</Box>
		</Drawer>
	);
});
