import { useSnackBarMessage, STATUS } from 'hooks/useSnackbar';
import { useEffect, useRef, useCallback } from 'react';
import { ValidationSchema } from './utils/validation';
import { getTypeAction } from 'utils/actionTypes';
import useBus, { dispatch } from 'use-bus';
import { useSelector } from 'react-redux';
import {
	useCreateSerialNumberMutation,
	useUpdateSerialNumberMutation,
	useDeleteSerialNumberMutation
} from './../../api/serial-number.js';

import CompanyDropdown from 'reusables/fields/CompanyDropdown';
import CustomTextField from 'components/Fields/TextField';
import FormikWrapper from 'components/Form/FormikWrapper';
import FormikForm from 'components/Form/Formik';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const FormikCompanyDropdown = FormikWrapper(CompanyDropdown);
const FormikTextField = FormikWrapper(CustomTextField);
const DISPATCH_NAME = 'modal/serial-numbers';
const FORM_TYPES = {
	ADD: 'ADD',
	EDIT: 'EDIT',
	VIEW: 'VIEW',
	DELETE: 'DELETE'
};

export default function Form({ eventName }) {
	const form = useSelector((state) => state.serialNumber.form);
	const modal = useSelector((state) => state.serialNumber.modal);
	const formRef = useRef();

	const { setSnackBarMessage } = useSnackBarMessage();
	const [createSerialNumber, { status: AddStatus }] =
		useCreateSerialNumberMutation();
	const [updateSerialNumber, { status: EditStatus }] =
		useUpdateSerialNumberMutation();
	const [deleteSerialNumber, { status: DeleteStatus }] =
		useDeleteSerialNumberMutation();
	const onViewModal = modal.type === FORM_TYPES.VIEW;

	const memoizedStatusDispatcher = useCallback(() => {
		if (
			AddStatus === 'fulfilled' ||
			EditStatus === 'fulfilled' ||
			DeleteStatus === 'fulfilled'
		) {
			setSnackBarMessage(
				`Succesfully ${getTypeAction(modal.type)}`,
				STATUS.SUCCESS
			);
			dispatch(DISPATCH_NAME);
			dispatch('refetch');
		}
	}, [AddStatus, EditStatus, DeleteStatus, setSnackBarMessage, modal.type]);

	useEffect(() => {
		memoizedStatusDispatcher();
	}, [memoizedStatusDispatcher]);

	useBus(
		eventName,
		() => {
			if (formRef.current) {
				formRef.current.handleSubmit();
			}
			if (modal.type === FORM_TYPES.DELETE) {
				const { id } = form;
				deleteSerialNumber(id);
			}
		},
		[formRef]
	);

	const handleFormSubmit = (values) => {
		if (modal.type === FORM_TYPES.ADD) {
			createSerialNumber(values);
		}
		if (modal.type === FORM_TYPES.EDIT) {
			const { id } = values;
			updateSerialNumber({
				id,
				body: values
			});
		}
	};

	return modal.type !== FORM_TYPES.DELETE ? (
		<FormikForm
			setErrors={{}}
			formName={eventName}
			innerRef={formRef}
			initialValues={form}
			enableReinitialize={true}
			validationSchema={ValidationSchema}
			onSubmit={(values) => handleFormSubmit(values)}
		>
			<FormikCompanyDropdown readOnly={onViewModal} />
			<Stack>
				<Box py={1}>
					<h3>Sales Invoice</h3>
					<Divider />
				</Box>
				<Stack spacing={1} direction="row">
					<FormikTextField
						label="Begin"
						name="si_begin"
						readOnly={onViewModal}
					/>
					<FormikTextField label="End" name="si_end" readOnly={onViewModal} />
				</Stack>
				<Box py={1}>
					<h3>Collection Receipt</h3>
					<Divider />
				</Box>
				<Stack spacing={1} direction="row">
					<FormikTextField
						label="Begin"
						name="cr_begin"
						readOnly={onViewModal}
					/>
					<FormikTextField label="End" name="cr_end" readOnly={onViewModal} />
				</Stack>
				<Box py={1}>
					<h3>Official Receipt</h3>
					<Divider />
				</Box>
				<Stack spacing={1} direction="row">
					<FormikTextField
						label="Begin"
						name="or_begin"
						readOnly={onViewModal}
					/>
					<FormikTextField label="End" name="or_end" readOnly={onViewModal} />
				</Stack>
				<Box py={1}>
					<h3>Service ID </h3>
					<Divider />
				</Box>
				<Stack spacing={1} direction="row">
					<FormikTextField
						label="Begin"
						name="sid_begin"
						readOnly={onViewModal}
					/>
					<FormikTextField label="End" name="sid_end" readOnly={onViewModal} />
				</Stack>
			</Stack>
		</FormikForm>
	) : (
		<>Do you want to delete this Serial Numbers ? </>
	);
}
