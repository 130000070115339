import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from 'app/apiClient';

export const serialNumberApi = createApi({
	reducerPath: 'serialNumberApi',
	baseQuery,
	tagTypes: ['SerialNumber'],
	endpoints: (builder) => ({
		getSerialNumber: builder.mutation({
			query: (body) => ({
				url: '/serial-numbers/list',
				method: 'GET',
				params: body
			}),
			transformResponse: (response, meta, arg) => {
				return response.items.map((item) => ({
					label: item.serial_number,
					value: {
						company_id: item.company_id,
						serial_id: item.serial_id,
						serial_type: item.serial_type,
						serial_number: item.serial_number
					}
				}));
			}
		})
	})
});

// Export the hooks as named exports
export const { useGetSerialNumberMutation } = serialNumberApi;
