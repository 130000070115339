import 'regenerator-runtime';
import FileSaver from 'file-saver';
import Excel from 'exceljs';
import _ from 'lodash';
/*
 * this method calls by event v-on:click button excel,
 * this exports excel
 */
const exportExcel = (data = [], headers = [], export_title = 'Unnamed') => {
	const workbook = new Excel.Workbook();
	const worksheet_title = export_title;
	let worksheet = workbook.addWorksheet(`${export_title} List`);

	// set bold first rows
	let font = { size: 11, bold: true };

	// this set the columns for worksheet in excel that will be export
	worksheet.columns = headers;

	/*
	 *  To iterate the column per header
	 */
	for (let column = 0; column <= headers.length; column++) {
		worksheet.getColumn(column);
	}

	data.map((row, key) => {
		worksheet.addRow(headers.map((header, key) => _.get(row, header.name)));
	});

	// save the file base on format extension
	workbook.xlsx.writeBuffer().then((data) => {
		const blob = new Blob([data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
		});
		FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
	});
};

export { exportExcel };
