import { useCallback, useEffect, useState } from 'react';
import { useGetCompanyMutation } from './api/companies';
import { useFormikContext } from 'formik';

import Autocomplete from 'components/Fields/AutoComplete';
import _ from 'lodash';

export default function CustomDropdown({
	label,
	required,
	value,
	...otherProps
}) {
	const {
		setFieldValue,
		values: { company_id }
	} = useFormikContext();
	const [getInvoices, { data, isLoading: loading }] = useGetCompanyMutation();
	const [options, setCompanyOptions] = useState(0);

	const memoizedSetAmountLimit = useCallback(() => {
		if (company_id) {
			const [company] = data?.items.filter((d) => d.id === company_id) ?? [];
			const limit = parseInt(_.get(company, 'amount_limit', 0));
			const totalAmount = _.get(company, 'total_amount') ?? 0;

			let amountLimit;
			if (limit > totalAmount) {
				amountLimit = limit - totalAmount;
			} else if (limit < totalAmount) {
				amountLimit = totalAmount - limit;
			} else if (limit === totalAmount) {
				amountLimit = 0;
			}

			setFieldValue('amount_limit', amountLimit);
		}
	}, [setFieldValue, data, company_id]);

	const memoizedGetInvoice = useCallback(() => {
		getInvoices();
	}, [getInvoices]);

	useEffect(() => {
		memoizedGetInvoice();
	}, [memoizedGetInvoice]);

	useEffect(() => {
		memoizedSetAmountLimit();
	}, [memoizedSetAmountLimit]);

	useEffect(() => {
		setCompanyOptions(
			data?.items.map((item) => ({
				label: item.name,
				value: item.id
			}))
		);
	}, [data?.items]);

	const configDropdownSelect = {
		...otherProps,
		options,
		loading,
		disabled: loading,
		isRequired: required,
		name: 'company_id',
		label: 'Company'
	};

	return <Autocomplete {...configDropdownSelect} />;
}
