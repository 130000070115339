import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import Box from '@mui/material/Box';

export default function ListItemCollapse({
	icon,
	path,
	children,
	disabled,
	closingDrawer
}) {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	/*
	 * This able to navigate other route by calling this method
	 */
	const handleNavigate = (path) => navigate(path);

	/*
	 * This will close the submenu when the drawer get close
	 */
	const handleSubMenuState = (e) => {
		e.stopPropagation();
		setOpen(!open);
	};

	/*
	 * This will close the submenu when the drawer get close
	 */
	useEffect(() => {
		if (!closingDrawer) setOpen(false);
	}, [closingDrawer]);

	return (
		<Fragment>
			<ListItemButton
				button
				disabled={disabled}
				onClick={(e) => handleNavigate(path)}
			>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary="Notifications" />
				<Box onClick={(e) => handleSubMenuState(e)}>
					{open ? <ExpandLess /> : <ExpandMore />}
				</Box>
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>
					{children?.map((items, index) => (
						<ListItemButton
							key={`li-${index}`}
							onClick={(e) => handleNavigate(items?.path)}
						>
							<ListItemIcon>{items?.icon}</ListItemIcon>
						</ListItemButton>
					))}
				</List>
			</Collapse>
		</Fragment>
	);
}
