import { useStyles } from 'components/Form/Fields/TextField/style';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import { Fragment } from 'react';

import FieldLabel from 'components/Form/Fields/FieldLabel';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import clsx from 'clsx';
import _ from 'lodash';

const disablePrevDates = (startDate) => {
	return (date) => Date.parse(date) < Date.parse(startDate);
};

export default function BasicDatePicker(props) {
	const {
		name,
		label,
		icon,
		required,
		disablePast,
		readOnly,
		disabled,
		format = 'MM/DD/yyyy',
		...otherProps
	} = props;

	const { values, errors, setFieldValue } = useFormikContext();
	const classes = useStyles();

	const configTextField = {
		...otherProps,
		size: 'small',
		variant: 'outlined',
		fullWidth: true,
		hiddenLabel: true,
		autoOk: true,
		InputProps: {
			startAdornment: (
				<InputAdornment position="start">
					<PersonIcon />
				</InputAdornment>
			)
		},
		className: clsx({
			[classes.root]: !otherProps?.error && !disabled,
			[classes.error]: otherProps?.error && !disabled,
			[classes.disabled]: disabled
		})
	};

	const configDatePicker = {
		...otherProps,
		disabled,
		readOnly,
		clearable: true,
		minDateMessage: '',
		value: values[name] ?? null,
		allowSameDateSelection: true,
		shouldDisableDate: disablePast && disablePrevDates(new Date()),
		onChange: (value) => {
			setFieldValue(name, value ? moment(value).format(format) : null);
		},
		renderInput: (params) => (
			<Fragment>
				<FieldLabel text={label} required={required} />
				<TextField
					{...configTextField}
					{...params}
					className={clsx({
						[classes.root]: !_.has(errors, name) && !disabled,
						[classes.error]: _.has(errors, name) && !disabled,
						[classes.disabled]: disabled
					})}
					error={errors[name] && !disabled}
				/>
			</Fragment>
		)
	};

	// when there's an error on errors obj
	if (errors[name] && !disabled) {
		configTextField.error = true;
		configTextField.helperText = errors[name];
	}

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DatePicker {...configDatePicker} />
		</LocalizationProvider>
	);
}
