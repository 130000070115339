import { useState } from 'react';

import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Companies from './Companies';
import Clients from './Clients';
import Agents from './Agents';

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
};

export default function InventoryTabs() {
	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<Box
				py={2}
				display="flex"
				component="main"
				alignItems="center"
				flexDirection="column"
				justifyContent="center"
			>
				<Box width="80%">
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={value}
							variant="fullWidth"
							onChange={handleChange}
							aria-label="icon tabs example"
						>
							<Tab label="Companies" aria-label="companies" />
							<Tab label="Clients" aria-label="clients" />
							<Tab label="Agents" aria-label="agents" />
						</Tabs>
					</Box>
				</Box>
			</Box>

			<TabPanel value={value} index={0}>
				{value === 0 && <Companies />}
			</TabPanel>
			<TabPanel value={value} index={1}>
				{value === 1 && <Clients />}
			</TabPanel>
			<TabPanel value={value} index={2}>
				{value === 2 && <Agents />}
			</TabPanel>
		</>
	);
}
