import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
	client_id: Yup.string().required('Client is Required').nullable(),
	company_id: Yup.string().required('Company is Required').nullable(),
	si: Yup.object().nullable(),
	tin: Yup.string().nullable(),
	address: Yup.string().nullable(),
	agent_code: Yup.string().nullable(),
	amount: Yup.string().nullable()
});
