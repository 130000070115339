import { TableBody as Body, TableRow, TableCell } from '@mui/material';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Menu from './TableMenu';
import _ from 'lodash';

export default function TableBody({ items, menu, headers }) {
	return (
		<Body>
			{items?.map((row, tbindex) => (
				<TableRow hover tabIndex={-1} key={`tb-key-${tbindex}`}>
					{headers?.map((header, thindex) => (
						<TableCell key={`th-key-${thindex}`}>
							{_.truncate(_.get(row, header.name), {
								length: 30,
								omission: '...'
							})}
						</TableCell>
					))}
					{menu?.show ? (
						<TableCell align="right">
							<Menu
								text={<MoreHoriz />}
								items={menu?.items}
								handleAction={(option) => menu?.callback({ option, row })}
							/>
						</TableCell>
					) : (
						<TableCell align="right"></TableCell>
					)}
				</TableRow>
			))}
		</Body>
	);
}
