import { setForm, defaultState } from '../../reducers/companySlice';
import { useGetCompanyMutation } from '../../api/companies';
import useBus, { dispatch } from 'use-bus';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import Table from 'components/Table';
import _ from 'lodash';

const defaultPagination = {
	perPage: 5,
	page: 1,
	total_items: 10
};

export default function InstanceTable({ headers, actions }) {
	const [getCompany, { data, isLoading }] = useGetCompanyMutation();
	const { items, ...pagination } = data ?? {};
	const stateDispatch = useDispatch();

	useEffect(() => {
		getCompany(defaultPagination);
	}, []);

	useBus(
		'refetch',
		() => {
			getCompany(defaultPagination);
		},
		[]
	);

	return (
		<Table
			data={{
				loading: isLoading,
				headers,
				rows: {
					items: items?.map((row) => ({
						...row,
						amount_limit: parseInt(
							_.get(row, 'amount_limit', 0) === null
								? 0
								: _.get(row, 'amount_limit', 0)
						).toLocaleString('en-PH', {
							style: 'currency',
							currency: 'PHP'
						}),
						amount_decimal: _.get(row, 'amount_limit', 0)
					}))
				},
				paginate: {
					...defaultPagination,
					...pagination,
					callback: (pagination) => {
						getCompany(pagination);
					}
				},
				add_button: {
					show: true,
					text: '+ Add Company',
					callback: () => {
						dispatch('modal/companies');
						stateDispatch(setForm(defaultState));
					}
				},
				menu: {
					show: true,
					items: actions,
					callback: (menu) => {
						dispatch('modal/companies');
						stateDispatch(setForm(menu));
					}
				},
				search: {
					show: true,
					callback: (keyword) => {
						getCompany({
							...defaultPagination,
							search: keyword
						});
					}
				}
			}}
		/>
	);
}
